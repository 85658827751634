import Timeline from '../../ui/timeline/Timeline';
import TimelineCard from '../../ui/timeline/TimelineCard';
import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';

const timelines = [
  {
    id: 1,
    title: 'Phoenix Defense - TRACR',
    description: `Award of technical excellence. I was brought on a team that was struggling and was able to get them out of from being one year behind and over budget.`,
    date: 'Oct 2021',
    role: 'Award of excellence',
    roleDate: 'Oct 2021',
    award: true
  },
  {
    id: 2,
    title: 'Phoenix Defense - TRACR',
    description: `Developed/maintained military training software. Worked on a team of ~15 as Senior Software Engineers. Split time between frontend/backend, also responsible for junior devs and architecture/design of application.`,
    date: 'Oct 2019',
    role: 'Senior Software Engineer',
    roleDate: 'Oct 2019 - Present',
    tags: ['Typescript', 'Angular', 'Java', 'Spring']
  },
  {
    id: 3,
    title: 'Riptide - Elements',
    description: `Developed commercial learning software for businesses's employee training. Most of work done on frontend in React, backend in node.js deployed on AWS.`,
    date: 'Apr 2016',
    role: 'Software Engineer',
    roleDate: 'Apr 2016 - Oct 2019',
    tags: ['Javascript', 'React', 'Node.js']
  },
  {
    id: 4,
    title: 'Technobuffs - Exellence award',
    description: `Was tasked with a problem that several other software companies failed to do and made it happen within several months. This saved our client hundreds of thousands of dollars and helped bring in a lot of revenue.`,
    date: 'Jun 2015',
    role: 'Software Engineer',
    roleDate: 'Jun 2015',
    tags: ['javascript', 'C#', '.NET'],
    award: true
  },
  {
    id: 5,
    title: 'Technobuffs',
    description: `Worked as software developer for an IT company, primarily focused on C#/Web applications. Wrote mobile app for android/ios used in tracking trucks for major shipping/moving company.`,
    date: 'Jun 2014',
    role: 'Software Engineer',
    roleDate: 'Jun 2013 - Mar 2016',
    tags: ['C#', '.NET', 'Objective-C', 'Java', 'ios', 'android']
  },
  {
    id: 6,
    title: "Bachelor's Degree - University of Mass Dartmouth",
    description: `Recieved my bachelor's degree in computer science. Several classes focused heavily on Artificial Intelligence a big interest of mine. Graduated with a 3.8 GPA`,
    date: 'Jun 2014',
    role: 'Student',
    roleDate: 'Sep 2012 - Jun 2014',
    tags: ['Java', 'C++', 'Neural Networks', 'Genetic Algorithms']
  },
  {
    id: 7,
    title: 'NHTI - Associates Degree',
    description: `Spent first two years in college in a game programming major. Learned several different technologies used in the game industry and did a lot of graphics processing.  Graduated with a 3.9 GPA`,
    date: 'Jun 2011',
    role: 'Student',
    roleDate: 'Sept 2009 - Jun 2011',
    tags: ['C++', 'C#', 'Java', 'OpenGL', 'DirectX']
  },
  {
    id: 8,
    title: 'NHTI AGGP Student of the Year',
    description: `Receieved top student of the year award both years in this major. Was chosen due to academic scores and results of senior project. A multiplayer real time strategy game in Unreal Engine.`,
    date: 'June 2010',
    role: 'Student',
    roleDate: 'Sept 2009 - Jun 2011',
    award: true
  }
];

export default function AppAccomplishments({ appWindow }: AppWindowProps) {
  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow}></AppWindowHeader>

      <AppWindowContent className='relative w-full h-full overflow-y-auto overflow-x-hidden'>
        <Timeline>
          {timelines.map((t, i) => (
            <TimelineCard key={t.id} {...t} left={i % 2 === 0} right={i % 2 === 1}></TimelineCard>
          ))}
        </Timeline>
      </AppWindowContent>
    </AppWindowContainer>
  );
}

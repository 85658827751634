export function darkenColor(rgbString: string, percent: number): string {
  // Parse the RGB values out of the string
  let [r, g, b] = rgbString.split(',').map(Number);

  // Make sure percent is in the range 0 to 1
  percent = Math.max(0, Math.min(1, percent));

  // Calculate the new RGB values
  r = Math.round(r * (1 - percent));
  g = Math.round(g * (1 - percent));
  b = Math.round(b * (1 - percent));

  // Return the new color as a string
  return `${r}, ${g}, ${b}`;
}

export function lightenColor(rgbString: string, percent: number) {
  // Parse the RGB values out of the string
  let [r, g, b] = rgbString.split(',').map(Number);

  // Make sure percent is in the range 0 to 1
  percent = Math.max(0, Math.min(1, percent));

  // Calculate the new RGB values
  r = Math.round(r + (255 - r) * percent);
  g = Math.round(g + (255 - g) * percent);
  b = Math.round(b + (255 - b) * percent);

  // Return the new color as a string
  return `${r}, ${g}, ${b}`;
}
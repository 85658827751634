import clsx from 'clsx';
import { installedApps } from '../../data/apps';
import { AppWindow } from '../../models/appWindow';
import { useGlobalStore } from '../../state/store';
import IconButton from '../ui/IconButton';
import { selectActiveWindowId } from '../../state/windowSlice';
import useDoubleClick from '../../hooks/UseDoubleClick';
import { useRef } from 'react';

export interface AppWindowHeaderProps {
  appWindow: AppWindow;
}

const AppWindowHeader = ({ appWindow }: AppWindowHeaderProps) => {
  const handleRef = useRef(null);
  const closeWindow = useGlobalStore((state) => state.closeWindow);
  const maximizeToggle = useGlobalStore((state) => state.maximizeToggle);
  const minmizeToggle = useGlobalStore((state) => state.minmizeToggle);
  const activeWindowId = useGlobalStore(selectActiveWindowId);
  const isFocused = activeWindowId === appWindow.id;
  const appData = installedApps[appWindow.app];

  // Handle header double click to maximize
  useDoubleClick({
    onDoubleClick: () => maximizeToggle(appWindow.id),
    ref: handleRef
  });

  const headerClass = clsx(
    'flex justify-between items-center h-[36px] window-handle text-sm select-none',
    { 'bg-base-300 text-base-contrast-300': isFocused },
    { 'bg-base-400 text-base-contrast-400': !isFocused }
  );

  return (
    <div className={headerClass} ref={handleRef}>
      <div className='flex'>
        <IconButton
          icon={appWindow.isMaximized ? 'compress' : 'expand'}
          className='px-3 py-2 hover:bg-base-300/80'
          onMouseDown={(e: React.SyntheticEvent<HTMLButtonElement>) => e.stopPropagation()}
          onClick={() => maximizeToggle(appWindow.id)}
          onTouchStart={() => maximizeToggle(appWindow.id)}
          title={appWindow.isMaximized ? 'Restore Down' : 'Maximize'}
        ></IconButton>
        <IconButton
          icon='minimize'
          className='px-3 py-2 hover:bg-base-300/80'
          onMouseDown={(e: React.SyntheticEvent<HTMLButtonElement>) => e.stopPropagation()}
          onClick={() => minmizeToggle(appWindow.id)}
          onTouchStart={() => minmizeToggle(appWindow.id)}
          title='Minimize'
        ></IconButton>
      </div>

      <div className=''>{appData.title}</div>

      <IconButton
        icon='close'
        className='text-base px-3 py-2 hover:bg-base-300/80'
        onMouseDown={(e: React.SyntheticEvent<HTMLButtonElement>) => e.stopPropagation()}
        onClick={() => closeWindow(appWindow.id)}
        onTouchStart={() => closeWindow(appWindow.id)}
        title='Exit'
      ></IconButton>
    </div>
  );
};

export default AppWindowHeader;

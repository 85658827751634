import { useCallback, useEffect, useRef } from 'react';
import { Timer } from '../models/timer';

/** Configuration options for Double Click Hook */
export interface UseDoubleClickProps {
  ref: React.RefObject<HTMLElement>;
  latency?: number;
  allowSingleClick?: boolean;
  onSingleClick?: (e: Event) => void;
  onDoubleClick?: (e: Event) => void;
}

/**
 * Hook that can be used for handling double click/double tap events on
 * any DOM element
 */
const useDoubleClick = ({
  ref,
  latency = 300,
  allowSingleClick = true,
  onSingleClick = () => null,
  onDoubleClick = () => null
}: UseDoubleClickProps) => {
  // Keep track of the current click count
  const clickRef = useRef(0);
  // Keep track of the set timeout token
  const timerRef = useRef<Timer>();

  // Determine if a double click has occured
  const checkForDoubleClick = useCallback(
    (e: Event) => {
      timerRef.current = undefined;

      if (clickRef.current === 2) {
        onDoubleClick(e);
      }

      clickRef.current = 0;
    },
    [onDoubleClick]
  );

  // Handle Click and Tap events
  const handleClick = useCallback(
    (e: Event) => {
      clickRef.current += 1;

      if (allowSingleClick && clickRef.current === 1) {
        onSingleClick(e);
      }

      if (timerRef.current) {
        clearTimeout(timerRef.current);
        checkForDoubleClick(e);
      } else {
        timerRef.current = setTimeout(() => checkForDoubleClick(e), latency);
      }
    },
    [onSingleClick, checkForDoubleClick, allowSingleClick, latency]
  );

  // Setup listeners for click/touch events
  useEffect(() => {
    const componentRef = ref.current;

    if (componentRef) {
      // Add event listeners for touch/click events
      componentRef.addEventListener('touchstart', handleClick, { passive: true });
      componentRef.addEventListener('click', handleClick, { passive: true });

      // Remove event listeners
      return () => {
        componentRef.removeEventListener('touchstart', handleClick);
        componentRef.removeEventListener('click', handleClick);
      };
    }
  }, [handleClick, ref]);
};

export default useDoubleClick;

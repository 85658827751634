import { savedFiles } from '../data/files';
import { AppFile } from '../models/file';

export interface FileState {
  files: Record<string, AppFile>;
}

const files = savedFiles.reduce((files: Record<string, AppFile>, file: AppFile, index) => {
  const id = `${index}`;
  files[id] = { ...file, id };

  return files;
}, {});

export const createFileSlice: (set: (fn: (state: FileState) => Partial<FileState>) => void) => FileState = () => ({
  files
});

export const selectFiles = (state: FileState) => Object.values(state.files);

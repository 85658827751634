import { Breakpoint } from '../state/app';
import { AppName } from './app';

export interface AppWindowDimensions {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export interface AppWindow {
  id: string;
  app: AppName;
  dimensions: AppWindowDimensions;
  previousDimensions: AppWindowDimensions;
  isMinimized: boolean;
  isMaximized: boolean;
  breakpoint: Breakpoint;
  args: string[];
}

export type AppWindows = Record<string, AppWindow>;

export const getWindowIdSelector = function (id: string) {
  return `window-${id}`;
};

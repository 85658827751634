export type DesktopIconTypes =  'small' | 'medium' | 'large';

export interface DesktopConfig {
  sortBy: string | null;
  iconSize: DesktopIconTypes;
}

export interface DesktopState {
  desktopConfig: DesktopConfig;
  selectedFile: string | null;
  selectFile: (id?: string | undefined) => void;
  setSortBy: (sortBy: string) => void;
  setIconSize: (iconSize: DesktopIconTypes) => void;
}

const createDefaultConfigState = (): DesktopConfig => {
  return {
    sortBy: null,
    iconSize: 'medium'
  };
};

export const createDesktopSlice: (set: (fn: (state: DesktopState) => Partial<DesktopState>) => void) => DesktopState = (
  set
) => ({
  desktopConfig: createDefaultConfigState(),
  selectedFile: null,
  selectFile: (id: string | undefined) => set(() => ({ selectedFile: id })),
  setSortBy: (sortBy: string) => set((state) => ({ desktopConfig: { ...state.desktopConfig, sortBy } })),
  setIconSize: (iconSize: DesktopIconTypes) => set((state) => ({ desktopConfig: { ...state.desktopConfig, iconSize } }))
});

export const selectSortBy = (state: DesktopState) => state.desktopConfig.sortBy;

export const selectIconSize = (state: DesktopState) => state.desktopConfig.iconSize;

export const selectActiveFile = (state: DesktopState) => state.selectedFile;
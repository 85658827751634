import { Timer } from '../models/timer';

/**
 * Ensure the provided function is only run with the specified timeout
 * @param callback function to call when the timeout has elapsed
 * @param delay time in milliseconds before calling the function
 * @returns 
 */
export function debounce<T extends (...args: unknown[]) => void>(
  callback: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timerId: Timer;

  return (...args: Parameters<T>) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}

import clsx from 'clsx';
import { useGlobalStore } from '../../state/store';

const DesktopWallpaper = () => {
  const { backgroundType, backgroundUrl, backgroundColor } = useGlobalStore((state) => state.themeConfig);

  const style = backgroundType === 'picture' ? { backgroundImage: `url('${backgroundUrl}')` } : { backgroundColor: `rgb(${backgroundColor})` };
  const className = clsx('absolute inset-0 transition-bg-image bg-cover bg-no-repeat bg-center bg-fixed select-none');

  return <div className={className} style={style}></div>;
};

export default DesktopWallpaper;

import { useEffect, useState } from 'react';
import { toPng } from 'html-to-image';
import { calculateAspectRatioDimensions } from '../util/aspectRatio';

export interface UseImageCaptureProps {
  id: string;
  width: number;
  height: number;
}

export interface ImageCaptureResult {
  dataUrl?: string;
  width: number;
  height: number;
}

export const useImageCapture = ({ id, width: targetWidth, height: targetHeight }: UseImageCaptureProps) => {
  const [imageData, setImageData] = useState<ImageCaptureResult>();

  useEffect(() => {
    const element = document.getElementById(id);

    if (element !== null) {
      const rect = element.getBoundingClientRect();
      const { width, height } = calculateAspectRatioDimensions(rect.width, rect.height, targetWidth, targetHeight);

      setImageData({ width, height });
    
      toPng(element)
        .then((dataUrl) => {
            setImageData({ dataUrl, width, height });
        })
        .catch((error) => {
          console.error('Error capturing image:', error);
        });
    }
  }, [id, targetWidth, targetHeight]);

  return imageData;
};

import { motion } from 'framer-motion';

import ToolbarAppList from './ToolbarAppList';

const animationVariant = {
  initial: {
    y: 100
  },
  animate: {
    y: 0,
    transition: {
      type: 'spring',
      damping: 10,
      stiffness: 100,
      duration: 1
    }
  }
};

const Toolbar = () => {
  return (
    <div className='fixed bottom-0 left-1/2 -translate-x-1/2 z-50'>
      <motion.div
        variants={animationVariant}
        initial='initial'
        animate='animate'
        className='flex bg-base-500/40 backdrop-blur-sm transition-colors h-[26px] xl:h-[32px] px-6 rounded-t-md bevel'
      >
        <ToolbarAppList />
      </motion.div>
    </div>
  );
};

export default Toolbar;

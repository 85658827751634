import { useGlobalStore } from '../../../state/store';
import { ThemeModes } from '../../../state/themeSlice';
import { isDarkModePreferred } from '../../../util/isDarkModePreferred';
import ColorGrid from '../../ui/ColorGrid';
import Select from '../../ui/Select';
import { themeColorOptions } from './supportedColors';

const preferDarkMode = isDarkModePreferred();
const preferedText = (isPreferred: boolean) => (isPreferred ? ' (Preferred)' : '');

const themeOptions = [
  { label: `Light${preferedText(!preferDarkMode)}`, value: 'light' },
  { label: `Dark${preferedText(preferDarkMode)}`, value: 'dark' },
  { label: `Purple`, value: 'purple' }
];

const ThemeColors = () => {
  const themeConfig = useGlobalStore((state) => state.themeConfig);
  const updateTheme = useGlobalStore((state) => state.updateTheme);

  return (
    <>
      <h2>Colors</h2>

      <div className='flex flex-col gap-4 w-fit'>
        <div>
          <h3>Choose your theme</h3>
          <Select
            value={themeConfig.themeMode}
            options={themeOptions}
            onSelect={(themeMode) => updateTheme({ themeMode: themeMode as ThemeModes})}
          ></Select>
        </div>

        <h3>Choose your accent color</h3>

        <div>
          <h4>Available Colors</h4>
          <ColorGrid
            value={themeConfig.accentColor}
            colors={themeColorOptions}
            onSelectColor={(accentColor) => updateTheme({ accentColor })}
          />
        </div>

        {/* <div className='mt-4 mb-6'>
          <h4>Show accent color on the following surfaces</h4>
          <div className='flex flex-col gap-3'>
            <Checkbox
              value={themeConfig.showAccentColorOnStartMenu}
              onChange={(showAccentColorOnStartMenu) => updateTheme({ showAccentColorOnStartMenu })}
            >
              Start, taskbar, and action center
            </Checkbox>
            <Checkbox
              value={themeConfig.showAccentColorOnToolbar}
              onChange={(showAccentColorOnToolbar) => updateTheme({ showAccentColorOnToolbar })}
            >
              Title bars and window borders
            </Checkbox>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ThemeColors;

import { AppWindow } from '../../models/appWindow';

export interface AppWindowContainerProps {
  appWindow: AppWindow;
  children: React.ReactNode;
}

const AppWindowContainer = ({ children }: AppWindowContainerProps) => {
  return <div className='flex flex-col w-full h-full animate-fadeIn'>{children}</div>;
};

export default AppWindowContainer;

import clsx from 'clsx';

export interface RibbonProps extends React.PropsWithChildren {}

const Ribbon = ({ children }: RibbonProps) => {
  return (
    <div
      className={clsx(
        'absolute w-[125px] h-[125px] overflow-hidden top-[-8px] right-[-8px] z-10',
        'before:absolute before:top-0 before:left-[26px] before:content-[""] before:z-[-1] before:border-4 before:border-accent before:brightness-75',
        'after:absolute after:bottom-[26px] after:right-[0] after:content-[""] after:z-[-1] after:border-4 after:border-accent after:brightness-75',
      )}
    >
      <div className={clsx('absolute w-[200px] py-1 bg-accent text-white text-center', 'left-[-15px] top-[20px] rotate-45')}>
        {children}
      </div>
    </div>
  );
};

export default Ribbon;

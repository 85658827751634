import { AppWindowProps } from '../../window/AppWindowShell';
import Button from '../../ui/Button';
import { useGlobalStore } from '../../../state/store';
import { AppWindowContainer, AppWindowContent } from '../../window';

export default function AppWelcome({ appWindow }: AppWindowProps) {
  const closeWindow = useGlobalStore((state) => state.closeWindow);

  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowContent className='flex flex-col justify-between pb-4 px-8 w-full h-full'>
        <div className='flex flex-col items-center text-center window-handle pt-2 font-thin'>
          <div className='hexagon flex items-center justify-center bg-accent w-[78px] h-[78px] text-3xl mb-2 animate-pulse'>
            JC
          </div>

          <h1>Welcome to Crosman OS!</h1>

          <p className='text-lg'>
            My personal portfolio stylized as an operating system. Have a look around and learn more about me and what I
            have to share.
          </p>

          <br />

          <p className='text-lg font-semibold text-accent-light'>
            Select one of the apps at the bottom of the screen to get started!
          </p>
        </div>

        <Button isHex onClick={() => closeWindow(appWindow.id)} className='text-lg'>I'm ready</Button>
      </AppWindowContent>
    </AppWindowContainer>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app.tsx';

import './styles/index.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

console.log(`   /\\   /\\   Joshua Crosman\r\n  //\\\\_//\\\\     ____\r\n  \\_     _/    /   /\r\n   / * * \\    /^^^]\r\n   \\_\\O/_/    [   ]\r\n    /   \\_    [   /\r\n    \\     \\_  /  /\r\n     [ [ /  \\/ _/\r\n    _[ [ \\  /_/`)

import resumePDF from '../../../assets/documents/Joshua_Crosman_Resume.pdf';
import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';

export default function AppResume({ appWindow }: AppWindowProps) {
  return (
    <AppWindowContainer appWindow={appWindow}>
       <AppWindowHeader appWindow={appWindow}></AppWindowHeader>

      <AppWindowContent className='flex w-full h-full'>
        <embed src={resumePDF} type='application/pdf' style={{ width: '100%', height: '100%' }} />
      </AppWindowContent>
    </AppWindowContainer>
  );
}

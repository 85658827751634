import { useState } from 'react';

import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';
import FontIcon from '../../ui/FontIcon';
import ThemeColors from './Colors';
import { AllProps } from '../../../models/global';
import ThemeBackground from './Background';

enum ThemeTabs {
  Background,
  Colors
}

interface SettingsItemProps extends AllProps {
  icon: string;
  children: React.ReactNode;
}

const SettingsItem: React.FC<SettingsItemProps> = ({ icon, children, ...props }) => {
  return (
    <div className='relative flex gap-4 items-center w-full hover:bg-window-hover px-4 py-3 cursor-pointer' {...props}>
      <FontIcon icon={icon} />
      <div className='text-sm'>{children}</div>
    </div>
  );
};

const tabComponentMap = {
  [ThemeTabs.Background]: ThemeBackground,
  [ThemeTabs.Colors]: ThemeColors
};

export default function AppTheme({ appWindow }: AppWindowProps) {
  const [tab, setTab] = useState(ThemeTabs.Colors);
  const TabComponent: React.FC = tabComponentMap[tab];
  const selectedOffset = 42 * tab;

  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow}></AppWindowHeader>

      <AppWindowContent className='flex h-full text-base-contrast-500'>
        <div className='flex flex-col gap-3 w-[250px] h-full bg-base-300 text-base-contrast-300'>
          <div className='px-4 py-1'>Settings</div>

          <div className='font-semibold px-4'>Personalization</div>

          <div className='flex flex-col relative'>
            <div
              className='absolute left-0 h-[30px] w-[4px] bg-accent mt-2 transition-all duration-200'
              style={{ top: `${selectedOffset}px` }}
            ></div>
            <SettingsItem icon='photo' onClick={() => setTab(ThemeTabs.Background)}>
              Background
            </SettingsItem>
            <SettingsItem icon='paint-brush' onClick={() => setTab(ThemeTabs.Colors)}>
              Colors
            </SettingsItem>
          </div>
        </div>

        <div className='p-4 w-full h-full bg-base-500 overflow-auto'>
          <TabComponent />
        </div>
      </AppWindowContent>
    </AppWindowContainer>
  );
}

import { useState, useRef, useEffect } from 'react';

import { App } from '../../models/app';
import ToolbarApp from './ToolbarApp';
import { useGlobalStore } from '../../state/store';
import { installedAppList } from '../../data/apps';
import { selectActiveWindowId, selectWindows } from '../../state/windowSlice';
import ToolbarAppPreview from './ToolbarAppPreview';
import { useHover } from '../../hooks/UseHover';
import { Timer } from '../../models/timer';
import { AppWindow } from '../../models/appWindow';

interface PreviewAppData {
  app: App;
  icon?: HTMLElement;
  x?: number;
  y?: number;
}

const ToolbarAppList = () => {
  const previewRef = useRef(null);
  const windows = useGlobalStore(selectWindows);
  const activeWindowId = useGlobalStore(selectActiveWindowId);
  const addWindow = useGlobalStore((state) => state.addWindow);
  const closeWindow = useGlobalStore((state) => state.closeWindow);
  const minmizeToggle = useGlobalStore((state) => state.minmizeToggle);
  const focus = useGlobalStore((state) => state.focusWindow);

  const [isPreviewHovered] = useHover({
    ref: previewRef
  });
  const [hoverData, setHoverData] = useState<PreviewAppData | null>(null);
  const [previewApp, setPreviewApp] = useState<PreviewAppData | null>(null);

  const handleClick = (app: App) => {
    const appWindow = windows.find((w) => w.app === app.name);
    if (appWindow) {
      const isFocused = appWindow.id === activeWindowId;

      if (appWindow.isMinimized || isFocused) {
        minmizeToggle(appWindow.id);
      }
      focus(appWindow.id, isFocused);

      setHoverData(null);
    } else {
      addWindow(app.name, []);
    }
  };

  useEffect(() => {
    const timeoutId: Timer = setTimeout(() => {
      if (previewApp && !hoverData && !isPreviewHovered) {
        setPreviewApp(null);
      } else if ((hoverData && !previewApp) || (previewApp && hoverData && previewApp.app !== hoverData?.app)) {
        const bounds = hoverData.icon?.getBoundingClientRect();

        setPreviewApp({ app: hoverData.app, x: bounds?.x, y: bounds?.y });
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [hoverData, isPreviewHovered]);

  const handleHover = (app: App, appWindow: AppWindow | undefined, ref: React.MutableRefObject<HTMLElement>) => {
    if (app && appWindow) {
      setHoverData({ app, icon: ref.current });
    } else {
      setHoverData(null);
    }
  };

  const handleHoverDone = () => {
    setHoverData(null);
  };

  const handleCloseWindow = (id: string) => {
    const appWindow = windows.find((w) => w.id === id);
    const appWindows = windows.filter((w) => w.app === appWindow?.app);

    closeWindow(id);

    if (appWindows.length === 1) {
      setPreviewApp(null);
      setHoverData(null);
    }
  };

  const handleFocusWindow = (id: string) => {
    focus(id, true);
    setPreviewApp(null);
    setHoverData(null);
  };

  return (
    <div className='flex gap-1 items-center'>
      {
        <ToolbarAppPreview
          app={previewApp?.app}
          x={previewApp?.x}
          closeWindow={handleCloseWindow}
          focusWindow={handleFocusWindow}
          ref={previewRef}
        />
      }

      {installedAppList
        .filter((app) => app.isPinned)
        .map((app, index) => {
          const appWindow = windows.find((w) => w.app === app.name);
          const isFocused = appWindow?.id === activeWindowId;

          return (
            <div key={app.name} data-grid={{ x: index, y: 0, w: 1, h: 1, isResizable: false }} className='relative'>
              <ToolbarApp
                app={app}
                isActive={!!appWindow}
                isFocused={isFocused}
                isMinimized={!!appWindow?.isMinimized}
                onClick={() => handleClick(app)}
                onHover={(ref) => handleHover(app, appWindow, ref)}
                onHoverDone={() => handleHoverDone()}
                openApp={() => addWindow(app.name, [])}
                closeApp={() => appWindow && closeWindow(appWindow?.id)}
                openMenu={() => setHoverData(null)}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ToolbarAppList;

import { motion } from 'framer-motion';

import { AppWindowContainer, AppWindowContent, AppWindowProps } from '../../window';
import IconButton from '../../ui/IconButton';
import { animateStaggerIn } from '../../../animations/stagger-in';
import HexagonIcon from '../../ui/HexagonIcon';

import './Contact.css';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useGlobalStore } from '../../../state/store';

function AnimIconButton({ icon, url }: { icon: string; url: string }) {
  return (
    <motion.div variants={animateStaggerIn} className='flex flex-col items-center gap-1'>
      <a href={url} target='_blank'>
        <IconButton icon={icon} />
      </a>
    </motion.div>
  );
}

export default function AppContact({ appWindow }: AppWindowProps) {
  const [isFlipped, setIsFlipped] = useState(false);
  const closeWindow = useGlobalStore(state => state.closeWindow);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFlipped(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowContent className='relative w-full h-full window-handle'>
        <div className='absolute right-3 top-2 z-10 text-xl'><IconButton icon={'close'} onClick={() => closeWindow(appWindow.id)} /></div>
        <div className='absolute inset-0 overflow-hidden'>
          <div className={clsx('container w-full h-full', { 'container-active': isFlipped })}>
            <div className='card w-full h-full rounded-md bg-base-400 text-base-contrast-400'>
              <div className='front flex flex-col items-center justify-center p-2 h-full w-full'>
                <HexagonIcon className='relative w-[128px] h-[128px] text-5xl'>JC</HexagonIcon>
              </div>

              <div className='flex flex-col justify-evenly back p-2'>
                <div>
                  <p className='text-4xl text-accent'>Joshua Crosman</p>
                  <p className='text-lg text-base-contrast-400/80'>Software Developer</p>
                </div>

                <div className='flex gap-6 text-5xl w-full justify-center text-base-contrast-400/80'>
                  <AnimIconButton icon='envelope' url='mailto:JACrosman@gmail.com' />
                  <AnimIconButton icon='github1' url='https://github.com/JACrosman' />
                  <AnimIconButton icon='linkedin1' url='https://www.linkedin.com/in/jacrosman/' />
                  <AnimIconButton icon='phone' url='tel:+6037034746' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWindowContent>
    </AppWindowContainer>
  );
}

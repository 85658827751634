import FontIcon from './FontIcon';
import './Hexagon.scss';

export interface HexagonProps extends React.PropsWithChildren {
  /** Color to display */
  color: string;

  /** Icon to display */
  icon: string;
}

/**
 * Renders an icon defined in the app font file
 */
const Hexagon = ({ children, color = 'blue', icon }: HexagonProps) => {
  return (
    <div className='w-[75px] ml-[25px]'>
      <div className={`hex-fancy hex-fancy-${color}`}>
        <div className='hex-fancy-circle'>
          <FontIcon icon={icon} className='mb-1'></FontIcon>
        </div>
        <div className="hex-fancy-ribbon">{children}</div>
      </div>
    </div>
  );
};

export default Hexagon;

import bg from '../assets/wallpaper/bg4.jpg';
import { darkenColor, lightenColor } from '../util/rgbDarken';

export type ThemeModes = 'dark' | 'light' | 'purple' | 'space';

export interface AppThemeConfig {
  /** Main color used across the app for buttons, highlights, etc */
  accentColor?: string;

  /** Theme light or dark */
  themeMode?: ThemeModes;

  /** Whether to use transparency effects (opacity) */
  useTransparency?: boolean;

  /** Whether to show accent color on Start, taskbar, and action center */
  showAccentColorOnStartMenu?: boolean;

  /** Whether to show accent color on title bars and window borders */
  showAccentColorOnToolbar?: boolean;

  /** Background type to use (picture, color) */
  backgroundType?: string;

  /** Background type to use (picture, color) */
  backgroundFit?: string;

  /** Background image to use */
  backgroundUrl?: string;

  /** Background color to use */
  backgroundColor?: string;
}

export interface AppThemeState {
  themeConfig: AppThemeConfig;
  resetTheme(): void;
  updateTheme(config: AppThemeConfig): void;
}

function createDefaultThemeConfig(): AppThemeConfig {
  return {
    accentColor: '2, 120, 212',
    themeMode: 'dark',
    useTransparency: true,
    showAccentColorOnStartMenu: false,
    showAccentColorOnToolbar: false,
    backgroundType: 'picture',
    backgroundUrl: bg,
    backgroundColor: '0, 0, 0'
  };
}

export const createThemeSlice: (
  set: (fn: (state: AppThemeState) => Partial<AppThemeState>) => void
) => AppThemeState = (set) => ({
  themeConfig: createDefaultThemeConfig(),
  resetTheme: () => set(() => ({ themeConfig: createDefaultThemeConfig() })),
  updateTheme: (themeConfig: AppThemeConfig) => {
    set((state: AppThemeState) => ({ themeConfig: { ...state.themeConfig, ...themeConfig } }));
  }
});

export function renderTheme(config: AppThemeConfig) {
  const { accentColor = '2, 120, 212' } = config;

  updateCssVar('accent', config.accentColor);
  updateCssVar('accent-dark', darkenColor(accentColor, 0.2));
  updateCssVar('accent-light', lightenColor(accentColor, 0.2));

  document.body.className = config.themeMode || 'dark';
}

function updateCssVar(name: string, value?: string) {
  if (value) {
    document.documentElement.style.setProperty(`--${name}`, value);
  }
}

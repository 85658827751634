import { motion } from 'framer-motion';

import { useGlobalStore } from '../../state/store';
import IconButton from '../ui/IconButton';
import HeaderDate from './HeaderDate';
import { Menu, MenuItem } from '@szhsin/react-menu';
import FontIcon from '../ui/FontIcon';

const animationVariant = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1
    }
  }
};

const Header = () => {
  const restartApp = useGlobalStore((state) => state.restartApp);
  const resetWindows = useGlobalStore((state) => state.resetWindows);
  const resetTheme = useGlobalStore((state) => state.resetTheme);

  const reset = () => {
    resetWindows();
    resetTheme();
  };

  const restart = () => {
    reset();
    restartApp();
  };

  return (
    <motion.div
      variants={animationVariant}
      initial='initial'
      animate='animate'
      className='fixed top-0 w-full flex justify-between items-center bg-base-300/50 backdrop-blur-sm transition-colors h-[32px] px-4 z-50 text-white'
    >
      <a
        href='mailto:JACrosman@gmail.com'
        target='_blank'
        className='flex gap-2 items-center cursor-pointer hover:brightness-90 active:brightness-75'
        aria-label='My Email'
      >
        <FontIcon icon='envelope'></FontIcon>
        <div className='mb-[3px]'>JACrosman@gmail.com</div>
      </a>
      <HeaderDate></HeaderDate>
      <div>
        <Menu menuButton={<IconButton icon='switch' title='Power Settings'></IconButton>} direction='left' transition>
          <MenuItem onClick={restart}>
            <FontIcon icon='switch'></FontIcon> Restart
          </MenuItem>
          <MenuItem onClick={reset}>
            <FontIcon icon='refresh'></FontIcon> Reset App
          </MenuItem>
        </Menu>
      </div>
    </motion.div>
  );
};

export default Header;

import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';

// Gallery
import img1 from '../../../assets/gallery/img1.jpg';
import img2 from '../../../assets/gallery/img2.jpg';
import img3 from '../../../assets/gallery/img3.jpg';
import img4 from '../../../assets/gallery/img4.jpg';
import img5 from '../../../assets/gallery/img5.jpg';
import img6 from '../../../assets/gallery/img6.jpg';
import img7 from '../../../assets/gallery/img7.jpg';
import img8 from '../../../assets/gallery/img8.jpg';
import img9 from '../../../assets/gallery/img9.jpg';
import img10 from '../../../assets/gallery/img10.jpg';
import img12 from '../../../assets/gallery/img12.jpg';
import img13 from '../../../assets/gallery/img13.jpg';
import img14 from '../../../assets/gallery/img14.jpg';
import img15 from '../../../assets/gallery/img15.jpg';
import img16 from '../../../assets/gallery/img16.jpg';
import { useGlobalStore } from '../../../state/store';
import { AppName } from '../../../models/app';

interface GalleryPhoto {
  name: string;
  img: string;
}

const galleryPhotos: GalleryPhoto[] = [
  { name: 'Daisy01', img: img1 },
  { name: 'Daisy02', img: img2 },
  { name: 'Daisy03', img: img3 },
  { name: 'Moby01', img: img4 },
  { name: 'Sylvester01', img: img5 },
  { name: 'Me01', img: img6 },
  { name: 'Moby02', img: img7 },
  { name: 'Moby03', img: img8 },
  { name: 'Moby04', img: img9 },
  { name: 'Moby05', img: img10 },
  { name: 'Moby06', img: img12 },
  { name: 'Moby07', img: img13 },
  { name: 'Me03', img: img14 },
  { name: 'Me04', img: img15 },
  { name: 'Daisy04', img: img16 }
];

const PhotoGallery = () => {
  const addWindow = useGlobalStore((state) => state.addWindow);

  const openPhoto = (photo: GalleryPhoto) => addWindow(AppName.Photos, [photo.img]);

  return (
    <AppWindowContent className='w-full h-full p-8 grid grid-cols-4 gap-8 overflow-auto'>
      {galleryPhotos.map((photo, index) => (
        <div key={index} className='relative w-full h-full'>
          <img
            src={photo.img}
            alt={photo.name}
            width='180'
            height='180'
            className='w-full h-full object-cover cursor-pointer aspect-square'
            onClick={() => openPhoto(photo)}
          />
          <div className='absolute bottom-0 text-center text-sm font-bold px-3 m-1 bg-black/80 text-white rounded-sm'>
            {photo.name}
          </div>
        </div>
      ))}
    </AppWindowContent>
  );
};

const PhotoViewer = ({ image }: { image: string }) => (
  <AppWindowContent className='flex justify-center w-full h-full overflow-hidden'>
    <img src={image} className='object-cover' />
  </AppWindowContent>
);
const AppPhotos = ({ appWindow }: AppWindowProps) => {
  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow}></AppWindowHeader>
      {appWindow.args?.length ? <PhotoViewer image={appWindow.args?.[0]} /> : <PhotoGallery />}
    </AppWindowContainer>
  );
};

export default AppPhotos;

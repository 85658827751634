export const skillsData = [
  {
    name: 'Frontend',
    years: '',
    skills: [
      { icon: 'javascript', title: 'Javascript', color: 'purple', crown: true },
      { icon: 'typescript', title: 'Typescript', color: 'blue' },
      { icon: 'html5', title: 'HTML', color: 'orange'},
      { icon: 'css3', title: 'CSS', color: 'blue-dark' },
      { icon: 'react', title: 'React', color: 'blue', crown: true },
      { icon: 'angular', title: 'Angular', color: 'red' },
      { icon: 'vue-dot-js', title: 'VueJs', color: 'green' }
    ]
  },
  {
    name: 'Backend',
    years: '',
    skills: [
      { icon: 'node-dot-js', title: 'node.js', color: 'green-dark', crown: true },
      { icon: 'next-dot-js', title: 'NextJs', color: 'pink' },
      { icon: 'spring', title: 'Spring', color: 'green' },
      { icon: 'dot-net', title: '.Net', color: 'purple' },
      { icon: 'mongodb', title: 'MongoDB', color: 'green' },
      { icon: 'java', title: 'Java', color: 'pink' },
      { icon: 'python', title: 'Python', color: 'blue-dark' },
      { icon: 'go', title: 'Go', color: 'blue' },
      { icon: 'cplusplus', title: 'C++', color: 'green' }
    ]
  },
  {
    name: 'Other Technologies/Languages',
    years: '',
    skills: [
      { icon: 'amazonaws', title: 'AWS', color: 'teal' },
      { icon: 'docker', title: 'Docker', color: 'teal' },
      { icon: 'electron', title: 'Electron', color: 'green-dark' },
    ]
  }
];

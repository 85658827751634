const ScrollInidicator = ({ ...props }) => {
  return (
    <a
      className='animate-bounce bg-base-400 p-2 w-10 h-10 ring-2 ring-accent shadow-lg rounded-full flex items-center justify-center cursor-pointer'
      aria-label='Scroll Down a Page'
      {...props}
    >
      <svg
        className='w-6 h-6 text-accent'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path d='M19 14l-7 7m0 0l-7-7m7 7V3'></path>
      </svg>
    </a>
  );
};

export default ScrollInidicator;

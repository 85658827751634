import AppList from './AppList';
import DragSelect from './DragSelect';
import DesktopWallpaper from './DesktopWallpaper';
import Header from '../header/Header';
import Toolbar from '../toolbar/Toolbar';
import AppWindowManager from '../window/AppWindowManager';
import WidgetList from '../widgets/WidgetList';
import { useGlobalStore } from '../../state/store';
import { Loader } from '../loader/Loader';
import { AppName } from '../../models/app';

const Desktop = () => {
  const isLoading = useGlobalStore(state => state.isLoading);
  const hasVisisted = useGlobalStore((state) => state.hasVisited);
  const setIsLoading = useGlobalStore(state => state.setIsLoading);
  const setHasVisisted = useGlobalStore((state) => state.setHasVisisted);
  const addWindow = useGlobalStore((state) => state.addWindow);


  const handleLoadingFinished = () => {
    setIsLoading(false);
    if (!hasVisisted) {
      setHasVisisted();
      addWindow(AppName.Welcome);
    }
  };

  return (
    <div className='w-full h-full flex flex-col justify-between'>
      <DesktopWallpaper />

      <Header />

      <AppList />

      <WidgetList />

      <AppWindowManager />

      <DragSelect />

      <Toolbar />

      {isLoading && <Loader done={handleLoadingFinished} /> }
    </div>
  );
};

export default Desktop;

export interface TextAreaProps {
  value: string;

  onChange: (value: string) => void;

  rows?: number;

  placeholder?: string;
}

const TextArea = ({ value, onChange, rows = 4, placeholder = '' }: TextAreaProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <textarea
      value={value}
      onChange={handleInputChange}
      rows={rows}
      placeholder={placeholder}
      className='block w-full py-1 px-3 border border-base-300 bg-base-400 shadow-sm rounded-sm focus:outline-none focus:ring-accent focus:border-accent resize-none text-base-contrast-400'
    />
  );
};

export default TextArea;

import { Variants } from "framer-motion";

export const widgetListVariant: Variants = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

export const widgetVariant: Variants = {
  initial: {
    x: 200,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1
  }
};
import { Breakpoint, breakpointWeights } from '../state/app';

type BreakpointDefinitions = Record<string, string>;

/**
 * Creates a custom responsive class based on the provided breakpoint 
 * @param breakpoint current breakpoint
 * @param baseClass Any base class names to use no matter the breakpoint
 * @param defaultClass default breakpoint class
 * @param definitions key value pair of breakpoint names to class names
 * @returns class names for a specific breakpoint
 */
export function bkpt(
  breakpoint: Breakpoint,
  baseClass: string,
  defaultClass: string,
  definitions: BreakpointDefinitions
): string {
  const breakpointWeight = breakpointWeights[breakpoint];
  let definitionClass = defaultClass;

  const breakpoints = Object.keys(definitions).reverse();

  for(const bp of breakpoints) {
    const bpWeight = breakpointWeights[bp as Breakpoint];
    if (bpWeight <= breakpointWeight) {
      definitionClass = definitions[bp];
      break;
    }
  }

  return `${baseClass} ${definitionClass}`;
}

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createWindowSlice } from './windowSlice';
import { AppThemeConfig, createThemeSlice } from './themeSlice';
import { createDesktopSlice } from './desktopSlice';
import { createFileSlice } from './fileSlice';
import { createAppSlice } from './app';
import { AppWindows } from '../models/appWindow';

interface PersistedState {
  windows: AppWindows;
  themeConfig: AppThemeConfig;
  hasVisited: boolean;
}

type StateFromFunctions<T extends [...any]> = T extends [infer F, ...infer R]
  ? F extends (...args: any) => object
    ? StateFromFunctions<R> & ReturnType<F>
    : unknown
  : unknown;

export type GlobalState = StateFromFunctions<
  [
    typeof createAppSlice,
    typeof createDesktopSlice,
    typeof createFileSlice,
    typeof createWindowSlice,
    typeof createThemeSlice
  ]
>;

export const useGlobalStore = create(
  persist(
    (set: (fn: (state: GlobalState) => Partial<GlobalState>) => void) => ({
      ...createAppSlice(set),
      ...createDesktopSlice(set),
      ...createFileSlice(set),
      ...createWindowSlice(set),
      ...createThemeSlice(set)
    }),
    {
      name: 'portfolio-store',
      storage: createJSONStorage(() => localStorage),
      partialize: (state: GlobalState): PersistedState =>
        ({
          windows: state.windows,
          themeConfig: state.themeConfig,
          hasVisited: state.hasVisited
        })
    }
  )
);

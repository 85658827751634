import { useEffect } from 'react';

import Desktop from './components/desktop/Desktop';
import { renderTheme } from './state/themeSlice';
import { useGlobalStore } from './state/store';

export default function App() {
  const themeConfig = useGlobalStore((state) => state.themeConfig);
  const calculateBreakpoint = useGlobalStore((state) => state.calculateBreakpoint);

  // Handle theme updates
  useEffect(() => {
    renderTheme(themeConfig);
  }, [themeConfig]);

  // Handle window resizing
  useEffect(() => {
    window.addEventListener('resize', calculateBreakpoint);

    return () => window.removeEventListener('resize', calculateBreakpoint);
  }, [calculateBreakpoint]);

  return <Desktop />;
}

import { useEffect } from 'react';

/** Configuration options for Double Click Hook */
export interface UseOutsideClickProps {
  ref: React.RefObject<HTMLElement>;

  onClickOutside: () => void;

  active?: boolean;
}

export function useOutsideClick({ ref, onClickOutside, active = true }: UseOutsideClickProps) {
  useEffect(() => {
    const componentRef = ref.current;

    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (componentRef && !componentRef.contains(event.target as Node)) {
        onClickOutside();
      }
    };

    if (active) {
      document.addEventListener('mousedown', handleClickOutside, { passive: true });
      document.addEventListener('touchstart', handleClickOutside, { passive: true });
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, onClickOutside, active]);
}


export const themeColorOptions = [
  '255, 185, 1',
  '255, 139, 0',
  '247, 99, 12',
  '202, 80, 15',
  '218, 59, 1',
  '239, 105, 80',
  '208, 52, 56',
  '255, 67, 66',
  '231, 72, 85',
  '230, 17, 35',
  '234, 0, 95',
  '196, 0, 83',
  '226, 1, 141',
  '192, 0, 119',
  '195, 57, 179',
  '155, 0, 138',
  '2, 120, 212',
  '0, 99, 177',
  '142, 139, 217',
  '107, 105, 213',
  '135, 100, 184',
  '116, 77, 168',
  '176, 70, 194',
  '135, 23, 151',
  '0, 153, 187',
  '45, 124, 154',
  '1, 183, 196',
  '2, 131, 137',
  '0, 178, 148',
  '1, 134, 117',
  '2, 204, 105',
  '16, 136, 62',
  '122, 116, 116',
  '94, 90, 87',
  '103, 118, 137',
  '80, 92, 106',
  '86, 124, 115',
  '73, 104, 96',
  '74, 130, 5',
  '16, 124, 15',
  '118, 118, 118',
  '75, 74, 72',
  '106, 121, 126',
  '75, 84, 89',
  '100, 124, 100',
  '82, 94, 84',
  '131, 117, 68',
  '126, 115, 95'
];
import { useState, useEffect } from 'react';

/** Display a box when click/dragging on the desktop */
const DragSelect = () => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);

  const handleMouseDown = (e: MouseEvent) => {
    if (
      (e.target as HTMLDivElement).id === 'desktop' ||
      (e.target as HTMLDivElement).className.includes('react-grid-layout')
    ) {
      setIsSelecting(true);
      setStartX(e.clientX);
      setStartY(e.clientY);
      setEndX(e.clientX);
      setEndY(e.clientY);
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isSelecting) return;
    setEndX(e.clientX);
    setEndY(e.clientY);
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown, { passive: true });
    window.addEventListener('mousemove', handleMouseMove, { passive: true });
    window.addEventListener('mouseup', handleMouseUp, { passive: true });

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  });

  const selectionStyles: React.CSSProperties = {
    position: 'absolute',
    width: Math.abs(endX - startX),
    height: Math.abs(endY - startY),
    border: '1px solid rgba(var(--accent))',
    background: 'rgba(var(--accent), 0.5)',
    pointerEvents: 'none',
    transformOrigin: 'top left',
    transform: `translate(${Math.min(startX, endX)}px, ${Math.min(startY, endY)}px)`
  };

  return isSelecting ? <div style={selectionStyles}></div> : null;
};

export default DragSelect;

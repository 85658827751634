import { useEffect, useState, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';

import './WeatherWidget.css';
import IconButton from '../../ui/IconButton';
import { widgetVariant } from '../widget-variants';
import ImageIcon from '../../ui/ImageIcon';

const NETLIFY_API = import.meta.env.VITE_NETLIFY_API;
const REFETCH_INTERVAL_MS = 3600000;

const weatherIconMap: { [key: string]: string } = {
  // Day
  '01d': 'day',
  '02d': 'cloudy-day-1',
  '03d': 'cloudy-day-2',
  '04d': 'cloudy-day-3',
  '09d': 'rainy-6',
  '10d': 'rainy-3',
  '11d': 'thunder',
  '13d': 'snowy-6',
  '50d': 'cloudy',
  // Night
  '01n': 'night',
  '02n': 'cloudy-night-1',
  '03n': 'cloudy-night-2',
  '04n': 'cloudy-night-3',
  '09n': 'rainy-6',
  '10n': 'rainy-3',
  '11n': 'thunder',
  '13n': 'snowy-6',
  '50n': 'cloudy'
};

const WeatherWidget = () => {
  const initialRender = useRef(true);
  const [temperature, setTemperature] = useState<number>(0);
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  function setWeatherData(data: any) {
    // Validate weather data
    if (data?.main && !isNaN(data.main.temp) && data.name?.length > 0) {
      setTemperature(Math.round(data.main.temp));
      setLocation(data.name);

      if (data.weather.length > 0) {
        const icon: string = weatherIconMap[data.weather[0].icon] || 'day';
        const iconUrl = new URL(`/src/assets/weather/${icon}.svg`, import.meta.url).href;

        setDescription(data.weather[0].description);
        setIcon(iconUrl);
      }
    } else {
      setError('Error fetching weather data');
    }
  }

  const getWeatherData = useCallback((refresh = true) => {
    async function fetchWeatherByCoordinates(lat: number, lon: number) {
      const WEATHER_API_PATH = `${NETLIFY_API}/weather?lat=${lat}&lon=${lon}&units=imperial`;
      setIsLoading(true);
      setError('');

      try {
        const response = await fetch(WEATHER_API_PATH);
        const data = await response.json();

        if (data.cod !== 200) {
          throw 'Invalid weather data response';
        }

        setWeatherData(data);

        // Store fetched data in local storage
        localStorage.setItem('weatherData', JSON.stringify(data));
        localStorage.setItem('weatherDataLastFetched', `${Date.now()}`);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(`Failed to fetch weather data: ${error.message}`);
        }
        setError('Error fetching weather data');
      }

      setIsLoading(false);
    }

    // Function to handle successful retrieval of user's location
    function requestWeatherData(latitude: number, longitude: number) {
      const weatherData = localStorage.getItem('weatherData');
      const lastFetched = localStorage.getItem('weatherDataLastFetched');

      if (!refresh && weatherData && lastFetched && Date.now() - Number(lastFetched) < REFETCH_INTERVAL_MS) {
        setWeatherData(JSON.parse(weatherData));
        setIsLoading(false);
      } else {
        fetchWeatherByCoordinates(latitude, longitude);
      }
    }

    function loadDefaultWeatherData() {
      // Default to Orlando, Florida
      requestWeatherData(28.5383, -81.3792);
    }

    // IF refreshing, request the user's location instead
    if (refresh) {
      // Request user's location
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }: GeolocationPosition) => requestWeatherData(coords.latitude, coords.longitude),
          loadDefaultWeatherData
        );
        return;
      }
    } else {
      loadDefaultWeatherData();
    }
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      getWeatherData(false);

      initialRender.current = false;
    }
  }, [getWeatherData]);

  const date = new Date();
  const monthName = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const showMessage = isLoading || !!error;

  return (
    <motion.div
      variants={widgetVariant}
      className='relative w-[275px] h-[175px] bg-base-500 shadow-md rounded-md text-base-contrast-400'
    >
      {!isLoading && (
        <IconButton
          icon='refresh'
          className='absolute top-0 right-0 p-2 text-base text-base-contrast-400/70 z-10'
          title='Refresh Weather Data'
          onClick={getWeatherData}
        ></IconButton>
      )}
      {showMessage && <div className='abs-center text-center'>{error || 'Loading Weather...'}</div>}
      {!showMessage && (
        <div className='animate-fadeIn flex flex-col justify-between  h-full'>
          <div className='relative text-7xl w-full h-full py-4 flex items-center justify-center rounded-t-md'>
            <ImageIcon icon={icon} width='100' height='100' alt={description} />
          </div>
          <div className='flex'>
            <div className='bg-base-400 flex flex-1 items-center just p-3 rounded-bl-md gap-2'>
              <div className='text-4xl'>{temperature}°</div>
              <div className='flex flex-col text-sm'>
                <div className='text-base-contrast-400/70'>{description}</div>
                <div>{location}</div>
              </div>
            </div>
            <div className='bg-accent px-2 text-center flex flex-col justify-center rounded-br-md text-white'>
              <div className='text-sm'>{monthName}</div>
              <div className='text-2xl'>{day}</div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default WeatherWidget;

import { Variants, motion } from 'framer-motion';
import clsx from 'clsx';

import ScrollInidicator from '../../ui/ScrollIndicator';
import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';
import me from '../../../assets/images/me.png';
import React from 'react';

const sectionVariant: Variants = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      when: 'beforeChildren', //use this instead of delay
      staggerChildren: 0.1
    }
  }
};

const titleVariant: Variants = {
  initial: {
    x: 200,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1
  }
};

const cardVariant: Variants = {
  initial: {
    y: 200,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1
  }
};

interface SectionProps {
  title: string;
  children: React.ReactNode;
  id: string;
  to?: string;
  className?: string;
}

const Section = ({ title, children, id, to, className }: SectionProps) => {
  return (
    <section id={id} className={clsx('min-h-full', className)}>
      <motion.div
        initial='initial'
        whileInView='animate'
        viewport={{ amount: 0.2, once: true }}
        variants={sectionVariant}
        className='flex flex-col justify-between items-center py-32 text-4xl font-semibold px-10 h-full'
      >
        <motion.div variants={titleVariant}>{title}</motion.div>
        <motion.div variants={titleVariant} className='mt-5 mb-12 w-64 h-[2px] bg-base-contrast-500'></motion.div>

        {children}

        {to && (
          <div className='mt-12'>
            <ScrollInidicator href={`#${to}`} aria-label={to} />
          </div>
        )}
      </motion.div>
    </section>
  );
};

interface SectionCardProps {
  label: string;
  value: string;
}

const SectionCard = ({ label, value }: SectionCardProps) => {
  return (
    <motion.div
      variants={cardVariant}
      className='w-56 h-56 p-2 rounded-md shadow-md bg-base-300 text-base-contrast-300 flex flex-col items-center border-t-4 border-accent'
    >
      <div className='flex items-center flex-1'>
        <div className='text-4xl text-center'>{value}</div>
      </div>
      <div className='text-lg text-base-contrast-300/70 font-bold'>{label}</div>
    </motion.div>
  );
};

export default function AppAbout({ appWindow }: AppWindowProps) {
  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow}></AppWindowHeader>

      <AppWindowContent className='h-full overflow-y-auto overflow-x-hidden scroll-smooth'>
        <section id='home' className='h-full '>
          <div className='flex flex-col justify-evenly items-center h-full text-5xl text-center font-thin animate-fadeIn gap-6'>
            <div>
              <div>Hello!</div>I am <span className='text-accent font-semibold'>Joshua Crosman</span>{' '}
              <div className='text-4xl text-base-contrast-500/70'>Full Stack Developer</div>
            </div>
            <ScrollInidicator href='#about' />
          </div>
        </section>

        <Section title='Here is a quick rundown' id='about' to='facts' className='bg-base-400'>
          <motion.div
            variants={titleVariant}
            className='relative max-w-prose p-6 bg-base-300 rounded-md text-lg text-base-contrast-300/80 font-thin border-t-4 border-accent shadow-md'
          >
            <div className='relative flex flex-col gap-3'>
              <p>My name is Joshua Crosman. I'm a Senior Software Engineer from Orlando, Florida.</p>

              <p>
                I have developed applications for both commercial and government (military) programs, dealing with all
                phases of the product lifecycle. Anywhere from gathering requirements from the customer, architecting
                the software, to handling fielding / releases.
              </p>

              <p>
                I currently live in Orlando, Florida but plan on relocating to the Boston, Mass area to be closer to
                family. I love spending time with my family and german shepherd/golden retriever dog Daisy!
              </p>
            </div>
          </motion.div>
        </Section>

        <Section title='How about a few facts' id='facts' to='me' className='bg-base-500'>
          <motion.div
            className='flex gap-3 flex-wrap justify-center'
            variants={sectionVariant}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true }}
          >
            <SectionCard label='Age' value='32'></SectionCard>
            <SectionCard label='Location' value='Orlando'></SectionCard>
            <SectionCard label='Work XP' value='10 years'></SectionCard>
            <SectionCard label='Favorite Language' value='Javascript'></SectionCard>
            <SectionCard label='Editor' value='VSCode'></SectionCard>
          </motion.div>
        </Section>

        <Section title='Here I am' id='me' className='bg-base-400'>
          <div className='flex'>
            <motion.div className='text-xl text-base-contrast-400/50 italic' variants={titleVariant}>I wonder what he's thinking...</motion.div>
            <motion.img src={me} className='filter grayscale max-w-[220px]' alt='Joshua Crosman Photo of self' width="220" height="608" variants={titleVariant} />
          </div>
        </Section>
      </AppWindowContent>
    </AppWindowContainer>
  );
}

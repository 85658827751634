import { useState } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useToggle } from 'react-use';

import avatar from '../../../assets/images/avatar_ears.png';
import Button from '../../ui/Button';
import IconButton from '../../ui/IconButton';
import { widgetVariant } from '../widget-variants';
import TextArea from '../../ui/TextArea';
import TextInput from '../../ui/TextInput';

const contactVariant: Variants = {
  initial: {
    y: '100%'
  },
  animate: {
    y: 0,
    transition: { type: 'easeIn' }
  },
  exit: {
    y: '100%',
    transition: { type: 'easeOut' }
  }
};

function AnimIconButton({
  icon,
  url,
  label,
  description
}: {
  icon: string;
  url: string;
  label: string;
  description: string;
}) {
  return (
    <div className='flex flex-col items-center gap-1'>
      <a href={url} target='_blank' aria-label={description}>
        <IconButton icon={icon} title={label} />
      </a>
    </div>
  );
}

const Profile = ({ toggleContactForm }: { toggleContactForm: () => void }) => {
  return (
    <>
      <div className='bg-base-500 w-full rounded-md'>
        <img
          src={avatar}
          className='max-w-[100px] m-auto px-4 pt-2'
          width='100'
          height='80'
          alt='Joshua Crosman Avatar'
        />
      </div>
      <div className='px-7 py-4 text-base-contrast-500'>
        <h3 className='m-0'>Joshua Crosman</h3>
        <h4 className='text-base-contrast-500/70 text-sm'>Software Developer</h4>
      </div>
      <div className='flex gap-6 text-xl mb-2 w-full justify-center text-base-contrast-400/80'>
        <AnimIconButton
          icon='envelope'
          url='mailto:JACrosman@gmail.com'
          label='My E-mail'
          description='Send an e-mail to me'
        />
        <AnimIconButton
          icon='github1'
          url='https://github.com/JACrosman'
          label='My Github'
          description='Checkout my github projects'
        />
        <AnimIconButton
          icon='linkedin1'
          url='https://www.linkedin.com/in/jacrosman/'
          label='My Linkedin'
          description='Checkout my linkedin profile'
        />
        <AnimIconButton icon='phone' url='tel:+6037034746' label='My Phone Number' description='Give me a call' />
      </div>
      <div>
        <Button icon='user-circle-o' className='w-full rounded-b-md text-white' onClick={toggleContactForm}>
          Contact
        </Button>
      </div>
    </>
  );
};

const ProfileContactForm = ({ toggleContactForm }: { toggleContactForm: () => void }) => {
  const [email, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setShowConfirmation(true);
    setTimeout(toggleContactForm, 1000);
  };

  return (
    <motion.form
      className='absolute inset-0 flex flex-col bg-base-500 px-3 py-1'
      variants={contactVariant}
      initial='initial'
      animate='animate'
      exit='exit'
      onSubmit={submitForm}
    >
      {showConfirmation ? (
        <div className='flex items-center justify-center h-full'>Message Submitted. Thank you!</div>
      ) : (
        <>
          <div className='flex items-center justify-center'>
            <h4 className='m-0 flex-1 text-base-contrast-400/90 font-semibold'>Say Hello!</h4>
            <IconButton icon={'close'} onClick={toggleContactForm} type='button' />
          </div>
          <div className='flex flex-col justify-between flex-1 py-2'>
            <TextInput value={email} onChange={setSubject} placeholder='Your E-mail' />
            <TextArea value={message} onChange={setMessage} placeholder='Type your message here'></TextArea>
            <Button disabled={!email || !message}>Send</Button>
          </div>
        </>
      )}
    </motion.form>
  );
};

const ProfileWidget = () => {
  const [isContactVisible, toggleContactForm] = useToggle(false);

  return (
    <motion.div
      variants={widgetVariant}
      className='relative bg-base-400 text-base-contrast-400 shadow-md text-center flex flex-col justify-between h-full rounded-md animate-fadeIn overflow-hidden'
    >
      <Profile toggleContactForm={toggleContactForm} />
      <AnimatePresence presenceAffectsLayout={false}>
        {isContactVisible && <ProfileContactForm toggleContactForm={toggleContactForm} />}
      </AnimatePresence>
    </motion.div>
  );
};

export default ProfileWidget;

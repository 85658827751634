import { useState } from 'react';

import { AllProps } from '../../../models/global';
import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';

const buttonList = [
  { label: '(', color: 1 },
  { label: ')', color: 1 },
  { label: 'CE', color: 1 },
  { label: 'C', color: 1 },
  { label: '%', color: 1 },
  { label: 'X²', color: 1 },
  { label: '√', color: 1 },
  { label: '÷', color: 1 },
  { label: '7' },
  { label: '8' },
  { label: '9' },
  { label: '*', color: 1 },
  { label: '4' },
  { label: '5' },
  { label: '6' },
  { label: '-', color: 1 },
  { label: '1' },
  { label: '2' },
  { label: '3' },
  { label: '+', color: 1 },
  { label: '+/-' },
  { label: '0' },
  { label: '.' },
  { label: '=', color: 3 }
];

const buttonClassMap: { [key: number]: string } = {
  1: 'bg-base-400 text-base-contrast-400 hover:bg-base-300 hover:text-base-contrast-300',
  2: 'bg-base-300 text-base-contrast-300 hover:bg-base-400 hover:text-base-contrast-400',
  3: 'bg-accent text-white hover:bg-accent-light'
};

interface CalculatorButtonProps extends AllProps {
  color: number | undefined;
}

const CalculatorButton = ({ children, color, ...props }: CalculatorButtonProps) => {
  return (
    <button className={`text-lg rounded-md ${buttonClassMap[color ?? 2]}`} {...props}>
      {children}
    </button>
  );
};

const AppCalculator = ({ appWindow }: AppWindowProps) => {
  const [inputs, setInputs] = useState<string[]>([]);
  const [display, setDisplay] = useState('0');
  const [isAnswer, setIsAnswer] = useState(false);

  const handleButtonClick = (value: string) => {
    switch (value) {
      case '=':
        calculate(inputs);
        break;
      case 'C':
        processInputs([]);
        break;
      case 'CE':
        processInputs(inputs.slice(0, -1));
        break;
      default:
        processInputs(addInput(inputs, value));
        break;
    }
  };

  const processInputs = (allInputs: string[]) => {
    const fullDisplay = allInputs
      .map((input) => {
        switch (input) {
          case 'X²':
            return '²';
          case '√':
            return '√(';
          default:
            return input;
        }
      })
      .join('');

    setIsAnswer(false);
    setInputs(allInputs);
    setDisplay(fullDisplay || '0');
  };

  const calculate = (allInputs: string[]) => {
    if (allInputs.length === 0) {
      return;
    }

    let currentOperand = '';
    let fullFormula = '';
    let result = '';

    for (const input of allInputs) {
      if (/[\d/√/(/)]/.test(input)) {
        currentOperand += input;
      } else if (/[+\-/*/.÷%]/.test(input)) {
        fullFormula += `${currentOperand}${input}`;
        currentOperand = '';
      } else if (/X²/.test(input)) {
        fullFormula += `Math.pow(${currentOperand}, 2)`;
        currentOperand = '';
      }
    }

    fullFormula += currentOperand;

    // Replace special characters
    fullFormula = fullFormula.replaceAll('÷', '/');
    fullFormula = fullFormula.replaceAll('√', 'Math.sqrt(');

    try {
      result = eval(fullFormula)
        .toFixed(2)
        .replace(/[.,]00$/, '');
    } catch {
      result = 'Error';
    }

    setIsAnswer(true);
    setDisplay(result);
    setInputs([result]);
  };

  const addInput = (nextInputs: string[], value: string) => {
    if (nextInputs.length === 0 && /[+\-/*/.÷%X²√]/.test(value)) {
      return [];
    }

    if (isAnswer) {
      return [value];
    }

    switch (value) {
      case '+/-':
        return nextInputs[0] === '-' ? nextInputs.slice(1) : ['-', ...nextInputs];
    }

    return nextInputs.concat(value);
  };

  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow}></AppWindowHeader>

      <AppWindowContent className='flex flex-col h-full bg-window-secondary'>
        <div className='text-window-contrast text-right font-semibold text-5xl py-[36px] px-4 text-ellipsis overflow-hidden'>
          {display}
        </div>
        <div className='grid grid-cols-4 p-1 gap-1 h-full'>
          {buttonList.map((b) => (
            <CalculatorButton key={b.label} color={b.color} onClick={() => handleButtonClick(b.label)}>
              {b.label}
            </CalculatorButton>
          ))}
        </div>
      </AppWindowContent>
    </AppWindowContainer>
  );
};

export default AppCalculator;

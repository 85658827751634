import { Variants, motion } from 'framer-motion';
import clsx from 'clsx';
import Ribbon from '../Ribbon';

export interface TimelineCardProps extends React.PropsWithChildren {
  left?: boolean;
  right?: boolean;
  title: string;
  description: string;
  date: string;
  role?: string;
  roleDate?: string;
  tags?: string[];
  award?: boolean;
}

const TimelineCard = ({
  children,
  left,
  right,
  title,
  description,
  date,
  role,
  roleDate,
  tags,
  award
}: TimelineCardProps) => {
  const containerClass = clsx('relative w-[50%] p-6 font-thin', { 'left-1/2': right });
  const markerClass = clsx(
    'absolute w-8 h-8 mt-2 bg-accent-dark rounded-full border-4 z-10 border-accent',
    { 'right-[-17px]': left },
    { 'left-[-17px]': right }
  );
  const dateClass = clsx(
    'absolute w-max top-[15%] text-base-contrast-500',
    { 'left-[110%]': !!left },
    { 'left-[-20%]': !!right }
  );
  const cardVariants: Variants = {
    offscreen: {
      x: right ? 100 : -100,
      opacity: 0
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  return (
    <motion.div
      className={containerClass}
      initial='offscreen'
      whileInView='onscreen'
      viewport={{ once: true, amount: 0.9 }}
    >
      <div className={markerClass}></div>
      <span className={dateClass}>{date}</span>
      <motion.div
        className='p-6 bg-base-400 shadow-md relative rounded-md text-base-contrast-400'
        variants={cardVariants}
      >
        {award && <Ribbon>Award</Ribbon>}
        <h2 className='font-semibold'>{title}</h2>
        {role && (
          <div className='bg-base-300 text-base-contrast-300/80 py-1 px-2 text-sm rounded flex justify-around'>
            <span className='flex-1'>{role}</span>
            <span className='flex-1'>{roleDate}</span>
          </div>
        )}
        <p className='text-lg my-3 text-base-contrast-400/80'>{description}</p>
        {tags && (
          <div className='flex gap-2 overflow-hidden text-base-contrast-300'>
            {tags.map((tag, i: number) => (
              <div className='bg-base-300 p-2' key={i}>
                {tag}
              </div>
            ))}
          </div>
        )}
      </motion.div>
      {children}
    </motion.div>
  );
};

export default TimelineCard;

import { useGlobalStore } from '../../../state/store';
import Select from '../../ui/Select';

// Wallpaper
import bg1 from '../../../assets/wallpaper/bg1.jpg';
import bg2 from '../../../assets/wallpaper/bg2.jpg';
import bg3 from '../../../assets/wallpaper/bg3.jpg';
import bg4 from '../../../assets/wallpaper/bg4.jpg';
import bg5 from '../../../assets/wallpaper/bg5.jpg';
import bg6 from '../../../assets/wallpaper/bg6.jpg';
import bg7 from '../../../assets/wallpaper/bg7.jpg';
import bg8 from '../../../assets/wallpaper/bg8.jpg';
import bg9 from '../../../assets/wallpaper/bg9.jpg';

// Wallpaper Thumbnails
import bg1Tn from '../../../assets/wallpaper-thumbnails/bg1-tn.jpg';
import bg2Tn from '../../../assets/wallpaper-thumbnails/bg2-tn.jpg';
import bg3Tn from '../../../assets/wallpaper-thumbnails/bg3-tn.jpg';
import bg4Tn from '../../../assets/wallpaper-thumbnails/bg4-tn.jpg';
import bg5Tn from '../../../assets/wallpaper-thumbnails/bg5-tn.jpg';
import bg6Tn from '../../../assets/wallpaper-thumbnails/bg6-tn.jpg';
import bg7Tn from '../../../assets/wallpaper-thumbnails/bg7-tn.jpg';
import bg8Tn from '../../../assets/wallpaper-thumbnails/bg8-tn.jpg';
import bg9Tn from '../../../assets/wallpaper-thumbnails/bg9-tn.jpg';


import ColorGrid from '../../ui/ColorGrid';
import { themeColorOptions } from './supportedColors';

const typeOptions = [
  { label: `Picture`, value: 'picture' },
  { label: `Solid color`, value: 'solid' }
];

// const fitOptions = [
//   { label: `Fill`, value: 'fill' },
//   { label: `Fit`, value: 'fit' },
//   { label: `Stretch`, value: 'stretch' },
//   { label: `Tile`, value: 'tile' },
//   { label: `Center`, value: 'center' },
//   { label: `Span`, value: 'span' }
// ];

const backgroundOptions = [
  { name: 'Hex Dark', bg: bg1, thumnail: bg1Tn },
  { name: 'Fennec Desert', bg: bg2, thumnail: bg2Tn },
  { name: 'Fennec Sleeping', bg: bg3, thumnail: bg3Tn },
  { name: 'Cubes', bg: bg4, thumnail: bg4Tn },
  { name: 'Hex Blue', bg: bg5, thumnail: bg5Tn },
  { name: 'Mountains', bg: bg6, thumnail: bg6Tn },
  { name: 'Windows', bg: bg7, thumnail: bg7Tn },
  { name: 'Toothless', bg: bg8, thumnail: bg8Tn },
  { name: 'Rainbow Paint', bg: bg9, thumnail: bg9Tn },
];

const ThemeBackgroundTile: React.FC<{ url: string; onClick: () => void }> = ({ url, onClick }) => {
  return (
    <img
      src={url}
      className='w-[150px] h-[84px] object-cover border-transparent border-2 hover:border-accent'
      onClick={onClick}
    />
  );
};

const ThemeBackground: React.FC = () => {
  const themeConfig = useGlobalStore((state) => state.themeConfig);
  const updateTheme = useGlobalStore((state) => state.updateTheme);
  const isPicture = themeConfig.backgroundType === 'picture';

  return (
    <>
      <h1>Background</h1>

      {isPicture ? (
        <img src={themeConfig.backgroundUrl} className='w-[300px] mb-6' alt='Desktop Background Image' />
      ) : (
        <div className='w-[300px] h-[170px]' style={{ backgroundColor: `rgb(${themeConfig.backgroundColor})` }}></div>
      )}

      <div className='flex flex-col gap-4 w-fit'>
        <div className='w-1/2'>
          <h4>Background</h4>
          <Select
            value={themeConfig.backgroundType}
            options={typeOptions}
            onSelect={(backgroundType) => updateTheme({ backgroundType })}
          ></Select>
        </div>

        {isPicture ? (
          <div>
            <h4>Choose your picture</h4>
            <div className='flex flex-wrap gap-2'>
              {backgroundOptions.map((bg) => (
                <ThemeBackgroundTile
                  key={bg.name}
                  url={bg.thumnail}
                  onClick={() => updateTheme({ backgroundUrl: bg.bg })}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>
            <h4>Choose your background color</h4>
            <div className='flex gap-2'>
              <ColorGrid
                value={themeConfig.backgroundColor}
                colors={themeColorOptions}
                onSelectColor={(backgroundColor) => updateTheme({ backgroundColor })}
              />
            </div>
          </div>
        )}

        {/* <div className='w-1/2'>
          <h4>Choose a fit</h4>
          <Select
            value={themeConfig.backgroundFit}
            options={fitOptions}
            onSelect={(backgroundFit) => updateTheme({ backgroundFit })}
          ></Select>
        </div> */}
      </div>
    </>
  );
};

export default ThemeBackground;

import { AllProps } from '../../models/global';

export interface ImageIconProps extends AllProps {
  /** Image icon to display (url) */
  icon: string;
  /** Width of image icon */
  width: string;
  /** Height of image icon */
  height: string;
}

/**
 * Render an icon from a url
 */
export default function ImageIcon({ icon, width, height, ...props }: ImageIconProps) {
  return <img src={icon} width={width} height={height} {...props} />;
}

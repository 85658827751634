import { forwardRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { App } from '../../models/app';
import ImageIcon from '../ui/ImageIcon';
import { AppWindow, getWindowIdSelector } from '../../models/appWindow';
import { useImageCapture } from '../../hooks/useImageCapture';
import IconButton from '../ui/IconButton';
import { useGlobalStore } from '../../state/store';
import { selectWindows } from '../../state/windowSlice';

export interface ToolbarAppPreviewProps {
  app?: App;
  x?: number;
  closeWindow: (id: string) => void;
  focusWindow: (id: string) => void;
}

const ToolbarAppPreviewWindow = ({
  app,
  appWindow,
  closeWindow,
  focusWindow
}: {
  app: App;
  appWindow: AppWindow;
  closeWindow: (id: string) => void;
  focusWindow: (id: string) => void;
}) => {
  const imageData = useImageCapture({
    id: appWindow?.id ? getWindowIdSelector(appWindow.id) : '',
    width: 150,
    height: 150
  });

  return (
    <div className='hover:bg-window-hover' onClick={() => focusWindow(appWindow.id)}>
      <div className='flex gap-2 justify-between items-center'>
        <div className='flex gap-2 px-2'>
          <ImageIcon icon={app?.icon} width='20px' height='20px' />
          <div className='text-sm'>{app?.name}</div>
        </div>
        {
          <IconButton
            icon='close1'
            className='w-[30px] h-[30px] hover:bg-warn text-xl'
            onClick={(e: React.SyntheticEvent<MouseEvent>) => {
              e.stopPropagation();
              closeWindow(appWindow.id);
            }}
          ></IconButton>
        }
      </div>

      <div
        style={{
          width: imageData?.width,
          height: imageData?.height
        }}
        className='px-2 pb-2'
      >
        {imageData?.dataUrl && <img src={imageData.dataUrl} className='w-full h-full' />}
      </div>
    </div>
  );
};

const ToolbarAppPreview = forwardRef<HTMLDivElement, ToolbarAppPreviewProps>(
  ({ app, x, closeWindow, focusWindow }, ref) => {
    const windows = useGlobalStore(selectWindows);
    const appWindows = windows.filter((w) => w.app === app?.name);
    const isHidden = !app;

    return (
      <div ref={ref}>
        <AnimatePresence>
          {!isHidden && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.25 }}
              className={`flex flex gap-2 absolute bottom-[48px] bg-window border-window-border z-50 transition-all`}
              style={{
                minHeight: 100,
                left: `${(x || 0) - (75 - 24)}px`
              }}
            >
              {appWindows.map((appWindow) => (
                <ToolbarAppPreviewWindow
                  app={app}
                  appWindow={appWindow}
                  closeWindow={closeWindow}
                  focusWindow={focusWindow}
                  key={appWindow.id}
                ></ToolbarAppPreviewWindow>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export default ToolbarAppPreview;

import { useState, useEffect, useRef } from 'react';
import { DraggableEvent } from 'react-draggable';
import { Position, Rnd } from 'react-rnd';
import { motion } from 'framer-motion';

import { AppWindow, getWindowIdSelector } from '../../models/appWindow';
import { Size } from '../../models/state';
import { useGlobalStore } from '../../state/store';
import { selectActiveWindowId } from '../../state/windowSlice';
import { MAX_STACK_ORDER } from '../../models/app';
import { AllProps } from '../../models/global';
import { installedAppComponentList } from '../../data/appComponents';

export interface AppWindowProps extends AllProps {
  appWindow: AppWindow;
}

/**
 * Renders an application inside a draggable/resizable window
 */
const AppWindowShell = ({ appWindow }: AppWindowProps) => {
  const { id, previousDimensions, dimensions, app, isMaximized } = appWindow || {};
  const activeWindowId = useGlobalStore(selectActiveWindowId);
  const [isReady, setIsReady] = useState(false);
  const isFocused = activeWindowId === id;

  // Window actions
  const setPosition = useGlobalStore((state) => state.setPosition);
  const setSize = useGlobalStore((state) => state.setSize);
  const maximizeToggle = useGlobalStore((state) => state.maximizeToggle);
  const focusWindow = useGlobalStore((state) => state.focusWindow);

  // Dynamically import the app using the app component name
  const AppComponent = installedAppComponentList[app];

  // State
  const windowRef = useRef(null);

  // Focus window
  const handleFocus = () => {
    if (!isFocused) {
      focusWindow(id);
    }
  };

  // Set specific window styles
  const style = {
    zIndex: isFocused ? MAX_STACK_ORDER : 0
  };

  // Add a short delay before rendering window content so animation can finish smoothly
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Rnd
      size={dimensions as Size}
      position={dimensions as Position}
      onDrag={(e: DraggableEvent) => {
        if (isMaximized) {
          let dim = { x: 0, y: 0 };

          if (e instanceof MouseEvent) {
            dim = { x: e.clientX, y: e.clientY };
          } else if (e instanceof TouchEvent && e.touches?.length) {
            const touch = e.touches[0];
            dim = { x: touch.clientX, y: touch.clientY };
          }

          maximizeToggle(id, {
            x: dim.x - (previousDimensions.width as number) / 2 || 0,
            y: dim.y - 10
          });
        }
      }}
      onDragStop={(_, d) => {
        if (dimensions.x !== d.x || dimensions.y !== d.y) {
          setPosition(id, d.x, d.y);
        }
      }}
      onResizeStop={(_e, _direction, ref, _delta, position) => {
        const rect = ref.getBoundingClientRect();
        setSize(id, rect.width, rect.height, position.x || 0, position.y || 0);
      }}
      minWidth={200}
      minHeight={200}
      dragHandleClassName='window-handle'
      style={style}
      onMouseDown={() => handleFocus()}
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.2 }}
        className='flex flex-col h-full border border-base-400 rounded-sm bg-base-500 text-base-contrast-500'
        id={getWindowIdSelector(id)}
        ref={windowRef}
      >
        {isReady && AppComponent && <AppComponent appWindow={appWindow} />}
      </motion.div>
    </Rnd>
  );
};

export default AppWindowShell;

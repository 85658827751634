// App components to render. May code split later, for now it's better user experience to have apps load instantly
import AppAccomplishments from '../components/apps/accomplishments/Accomplishments';
import AppDraw from '../components/apps/draw/Draw';
import AppAbout from '../components/apps/about/About';
import AppWelcome from '../components/apps/welcome/Welcome';
import AppSkills from '../components/apps/skills/Skills';
import AppContact from '../components/apps/contact/Contact';
import AppResume from '../components/apps/resume/Resume';
import AppProjects from '../components/apps/projects/Projects';
import AppCalculator from '../components/apps/calculator/Calculator';
import AppTheme from '../components/apps/theming/Theme';
import AppPhotos from '../components/apps/photos/Photos';
import AppSandbox from '../components/apps/sandbox/Sandbox';

import { AppName } from '../models/app';

export const installedAppComponentList: Record<AppName, any> = {
  [AppName.Welcome]: AppWelcome,
  [AppName.AboutMe]: AppAbout,
  [AppName.Skills]: AppSkills,
  [AppName.ContactMe]: AppContact,
  [AppName.Resume]: AppResume,
  [AppName.Projects]: AppProjects,
  [AppName.Accomplishments]: AppAccomplishments,
  [AppName.Draw]: AppDraw,
  [AppName.Calculator]: AppCalculator,
  [AppName.Theme]: AppTheme,
  [AppName.Photos]: AppPhotos,
  [AppName.Sandbox]: AppSandbox
};
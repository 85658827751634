import { useState } from 'react';

export interface TooltipProps extends React.PropsWithChildren {
  text: string;

  hide?: boolean;
}

const Tooltip = ({ children, text, hide }: TooltipProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className='relative' onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
      {children}
      {visible && !hide && (
        <div className='absolute left-1/2 -translate-x-1/2 bg-base-500 text-base-contrast-500 top-[-50px] p-2 whitespace-nowrap rounded-sm text-sm animate-fadeIn'>
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;

import { useRef, memo } from 'react';
import clsx from 'clsx';

import ImageIcon from '../ui/ImageIcon';
import useDoubleClick from '../../hooks/UseDoubleClick';
import { AppFile } from '../../models/file';
import { installedApps } from '../../data/apps';
import { useGlobalStore } from '../../state/store';
import { selectActiveFile } from '../../state/desktopSlice';
import FontIcon from '../ui/FontIcon';

export interface FileIconProps {
  file: AppFile;
  selectFile: () => void;
  addWindow: () => void;
  onContextMenu: (e: React.MouseEvent) => void;
}

const AppFileIcon = memo(({ file, selectFile, addWindow, onContextMenu }: FileIconProps) => {
  const ref = useRef(null);
  const selectedFile = useGlobalStore(selectActiveFile);
  const isSelected = file.id === selectedFile;
  const app = installedApps[file.appName];

  // Handle double click event
  useDoubleClick({
    onSingleClick: (e) => {
      e.stopPropagation();
      selectFile();
    },
    onDoubleClick: () => addWindow(),
    ref
  });

  const appIconClass = clsx('flex flex-col items-center justify-center text-center text-white w-full h-full border-accent', {
    'bg-accent/50 border-2': isSelected,
    'hover:bg-accent-light/40 hover:border-2': !isSelected
  });

  return (
    <div className={appIconClass} ref={ref} onContextMenu={onContextMenu}>
      {app?.icon && !file?.icon && (
        <div className='bg-gradient-to-b from-accent to-accent-dark flex items-center justify-center rounded-lg text-3xl w-[55px] h-[55px]'>
          <FontIcon icon={app.icon} />
        </div>
      )}
      {file?.icon && <ImageIcon icon={file.icon} width='42px' height='42px' alt={file.name} />}

      <div className='text-shadow font-semibold select-none'>{file.name}</div>
    </div>
  );
});

export default AppFileIcon;

import { AppName } from '../models/app';
import { AppFiles } from '../models/file';

import me001 from '../assets/gallery/img15.jpg';
import daisy001 from '../assets/gallery/img1.jpg';
import daisy002 from '../assets/gallery/img2.jpg';
import lighthouse from '../assets/images/lighthouse.jpg';
import me001Thumbnail from '../assets/gallery-thumbnails/img15-tn.jpg';
import daisy001Thumbnail from '../assets/gallery-thumbnails/img1-tn.jpg';
import daisy002Thumbnail from '../assets/gallery-thumbnails/img2-tn.jpg';
import resumeThumbnail from '../assets/images/resume-tn.jpg';
import resume from '../assets/documents/Joshua_Crosman_Resume.pdf';
import lighthouseThumbnail from '../assets/images/lighthouse-tn.jpg';

/** List of files to display on the desktop */
export const savedFiles: AppFiles = [
  // {
  //   name: 'Draw',
  //   appName: AppName.Draw
  // },
  {
    name: 'Calculator',
    appName: AppName.Calculator
  },
  {
    name: 'Sandbox',
    appName: AppName.Sandbox
  },
  {
    name: 'Resume',
    appName: AppName.Resume,
    icon: resumeThumbnail,
    args: [resume]
  },
  {
    name: 'Josh01',
    appName: AppName.Photos,
    icon: me001Thumbnail,
    args: [me001]
  },
  {
    name: 'Daisy01',
    appName: AppName.Photos,
    icon: daisy001Thumbnail,
    args: [daisy001]
  },
  {
    name: 'Daisy02',
    appName: AppName.Photos,
    icon: daisy002Thumbnail,
    args: [daisy002]
  },
  {
    name: 'Lighthouse',
    appName: AppName.Photos,
    icon: lighthouseThumbnail,
    args: [lighthouse]
  }
];

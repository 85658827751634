import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

import { useGlobalStore } from '../../state/store';
import { selectWindows } from '../../state/windowSlice';
import AppWindowShell from './AppWindowShell';
import { useWindowSize } from '../../hooks/UseWindowSize';

/**
 * Handles rendering of all application windows
 */
const AppWindowManager = () => {
  const windows = useGlobalStore(selectWindows);
  const validateDimensions = useGlobalStore((state) => state.validateDimensions);
  const size = useWindowSize();

  useEffect(() => {
    validateDimensions();
  }, [size, validateDimensions]);

  return (
    <div className='absolute'>
      <AnimatePresence initial={false} presenceAffectsLayout={false}>
        {windows
          .filter((w) => !w.isMinimized)
          .map((appWindow) => (
            <AppWindowShell appWindow={appWindow} key={appWindow.id} />
          ))}
      </AnimatePresence>
    </div>
  );
};

export default AppWindowManager;

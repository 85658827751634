import { motion } from 'framer-motion';
import ProfileWidget from './profile/ProfileWidget';
import WeatherWidget from './weather/WeatherWidget';
import { widgetListVariant } from './widget-variants';

const WidgetList = () => {
  return (
    <motion.section
      className='absolute right-0 top-0 mt-[50px] mr-[20px] flex flex-col gap-4'
      initial='initial'
      animate='animate'
      variants={widgetListVariant}
    >
      <ProfileWidget />
      <WeatherWidget />
    </motion.section>
  );
};

export default WidgetList;

import { forwardRef } from 'react';
import clsx from 'clsx';
import { AllProps } from '../../models/global';
import FontIcon from './FontIcon';

export interface IconButtonProps extends AllProps {
  /** Icon to display */
  icon: string;

  /** Classname to apply to container */
  className?: string;
}

/**
 * Renders a button with the app font icon component
 */
const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ icon, className, ...props }: IconButtonProps, ref) => {
  const fullClassName = clsx('hover:brightness-90 active:brightness-75', className);

  return (
    <button {...props} className={fullClassName} ref={ref}>
      <FontIcon icon={icon} />
    </button>
  );
});

export default IconButton;

import { useRef, useState, useEffect } from 'react';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import { Variants, motion } from 'framer-motion';
import clsx from 'clsx';

import { App } from '../../models/app';
import { AllProps } from '../../models/global';
import FontIcon from '../ui/FontIcon';
import Tooltip from '../ui/Tooltip';
import { Timer } from '../../models/timer';

export interface ToolbarAppProps extends AllProps {
  /** Application Data */
  app: App;

  /** Whether the app is open */
  isActive: boolean;

  /** Whether the app is minimized */
  isMinimized: boolean;

  /** Handle click event */
  onClick: () => void;

  /** Handle hover event */
  onHover: (ref: React.MutableRefObject<any>) => void;

  /** Handle hover out event */
  onHoverDone: () => void;

  /** Open app event */
  openApp: () => void;

  /** Open app event */
  closeApp: () => void;

  /** Open menu event */
  openMenu: () => void;
}

const bounceAnimation: Variants = {
  start: {
    y: 0
  },
  end: {
    y: -20,
    transition: {
      repeatType: 'reverse',
      repeat: 5,
      damping: 15,
      stiffness: 80,
      duration: 0.25
    }
  }
};

const ToolbarApp = ({
  app,
  isActive,
  isMinimized,
  isFocused,
  onClick,
  openApp,
  closeApp,
  openMenu
}: ToolbarAppProps) => {
  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: { open: true } });
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    let timer: Timer;

    if (isClicked) {
      timer = setTimeout(() => setIsClicked(false), 1500);
    }

    return () => clearTimeout(timer);
  }, [isClicked]);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    openMenu();
    toggleMenu(true);
  };

  const toolbarAppClass = clsx(
    'hexagon flex justify-center items-center cursor-pointer hover:brightness-125',
    'w-[54px] h-[54px] text-xl mb-[42px]',
    'xl:text-3xl xl:w-[60px] xl:h-[60px] xl:mb-[60px]',
    app.color || 'bg-gradient-grey'
  );

  const indicatorclass = clsx('absolute left-1/2 -translate-x-[5px] w-[10px] h-[10px] bottom-[-14px] rounded-full animate-fadeIn', {
    'bg-accent shadow-accent': isFocused,
    'bg-slate-300': !isFocused || isMinimized
  });

  return (
    <Tooltip text={app.title} hide={menuState.state === 'open'}>
      <motion.button
        className={toolbarAppClass}
        ref={ref}
        variants={bounceAnimation}
        initial='start'
        animate={isClicked ? 'end' : 'start'}
        onClick={() => {
          if (!isActive) {
            setIsClicked(true);
          }
          onClick();
        }}
        onContextMenu={handleOpenMenu}
        aria-label={app.title}
      >
        <FontIcon icon={app.icon} className='text-white'></FontIcon>
      </motion.button>
      {isActive && <div className={indicatorclass}></div>}

      <ControlledMenu
        {...menuState}
        anchorRef={ref}
        onClose={() => toggleMenu(false)}
        className={'szh-toolbar text-lg z-50'}
      >
        <MenuItem onClick={openApp}>
          <FontIcon icon={app.icon} className='text-xl' /> {app.title}
        </MenuItem>
        {isActive && (
          <MenuItem onClick={closeApp}>
            <div className='text-xl'>
              <FontIcon icon='close' />
            </div>
            Close
          </MenuItem>
        )}
      </ControlledMenu>
    </Tooltip>
  );
};

export default ToolbarApp;

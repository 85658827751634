import { installedApps } from '../data/apps';
import { AppName } from '../models/app';
import { AppWindow, AppWindowDimensions, AppWindows } from '../models/appWindow';
import { getWindowHeight, getWindowSize, getWindowWidth } from '../util/window';
import { Breakpoint } from './app';

const HEADER_SIZE = 32;

export interface AppWindowState {
  windows: AppWindows;
  activeWindow: string | null;
  resetWindows(): void;
  addWindow(appName: AppName, args?: string[], dimensions?: AppWindowDimensions | undefined): void;
  closeWindow(id: string): void;
  setPosition(id: string, x: number, y: number): void;
  setSize(id: string, width: number, height: number, x: number, y: number): void;
  minmizeToggle(id: string, minimize?: boolean): void;
  maximizeToggle(id: string, location?: { x: number; y: number }): void;
  validateDimensions(): void;
  focusWindow(id: string, isFocused?: boolean): void;
}

function updateWindow(windows: AppWindows, id: string, propFn: (window: AppWindow) => Partial<AppWindow>) {
  return {
    ...windows,
    [id]: {
      ...windows[id],
      ...propFn(windows[id])
    }
  };
}

function updateWindowDimensions(window: AppWindow, dimensions: Partial<AppWindowDimensions>, isMaximized = false) {
  const { width: windowWidth, height: windowHeight } = getWindowSize();
  const { x = 0, y = 0, width = window.dimensions.width || 0, height = window.dimensions.height || 0 } = dimensions;
  const halfWidth = width / 2;
  const halfHeight = height / 2;

  // Ensure window doesn't go outside the left
  if (x < -halfWidth) {
    dimensions.x = -halfWidth;
  }

  // Ensure window doesn't go outside the right
  if (x > windowWidth - halfWidth) {
    dimensions.x = windowWidth - halfWidth;
  }

  // Ensure window doesn't go outside top of screen
  if (y && y < HEADER_SIZE) {
    dimensions.y = HEADER_SIZE;
  }

  // Ensure window doesn't go outside the bottom
  if (y > windowHeight - halfHeight) {
    dimensions.y = windowHeight - halfHeight;
  }

  const newDemensions = { ...window.dimensions, ...dimensions };

  return {
    previousDimensions: { ...window.dimensions },
    dimensions: newDemensions,
    breakpoint: calculateBreakPoint(newDemensions),
    isMaximized
  };
}

function generateWindowId() {
  return new Date().getTime().toString();
}

export const createWindowSlice: (
  set: (fn: (state: AppWindowState) => Partial<AppWindowState>) => void
) => AppWindowState = (set) => ({
  windows: {},
  activeWindow: null,
  resetWindows: () => set(() => ({ windows: {} })),
  /** Add a new window to the desktop */
  addWindow: (appName: AppName, args: string[] = [], dimensions?: AppWindowDimensions) => {
    const app = installedApps[appName];
    const { width, height } = app.defaultSize;
    const { width: windowWidth, height: windowHeight } = getWindowSize(true);
    const isOutsideWindow = width >= windowWidth || height >= windowHeight;

    const windowDimensions: AppWindowDimensions = isOutsideWindow
      ? { width: windowWidth, height: windowHeight, x: 0, y: HEADER_SIZE }
      : {
          width,
          height,
          x: (getWindowWidth() - width) / 2,
          y: (getWindowHeight() - height) / 2,
          ...(dimensions || {})
        };
    const id = generateWindowId();

    set((state: AppWindowState) => ({
      windows: {
        ...state.windows,
        [id]: {
          id,
          app: appName,
          dimensions: windowDimensions,
          previousDimensions: { ...windowDimensions },
          isMinimized: false,
          isMaximized: isOutsideWindow,
          args,
          breakpoint: calculateBreakPoint(windowDimensions),
          elementRef: { current: null }
        }
      },
      activeWindow: id
    }));
  },
  /* Remove an existing window from the desktop */
  closeWindow: (id: string) =>
    set((state: AppWindowState) => ({
      windows: (({ [id]: _, ...others }) => others)(state.windows),
      activeWindow: null
    })),
  /* Sets window position */
  setPosition: (id: string, x: number, y: number) =>
    set((state: AppWindowState) => ({
      windows: updateWindow(state.windows, id, (window) => updateWindowDimensions(window, { x, y }))
    })),
  /* Sets window position */
  setSize: (id: string, width: number, height: number, x: number, y: number) =>
    set((state: AppWindowState) => ({
      windows: updateWindow(state.windows, id, (window) => updateWindowDimensions(window, { width, height, x, y }))
    })),
  /* Minimizes or reveals an existing window from the desktop */
  minmizeToggle: (id: string, minimize?: boolean) =>
    set((state: AppWindowState) => ({
      windows: updateWindow(state.windows, id, (window) => ({ isMinimized: minimize ?? !window.isMinimized }))
    })),
  /* Maximizes or un-maximizes an existing window from the desktop */
  maximizeToggle: (id: string, location?: { x: number; y: number }) =>
    set((state: AppWindowState) => ({
      windows: updateWindow(state.windows, id, (window) => {
        const isMaximized = !window.isMaximized;

        const updated = updateWindowDimensions(
          window,
          isMaximized
            ? { x: 0, y: 30, width: getWindowWidth(), height: getWindowHeight(true) }
            : {
                ...window.previousDimensions,
                x: location?.x ?? window.previousDimensions.x,
                y: location?.y ?? window.previousDimensions.y
              },
          isMaximized
        );

        return updated;
      })
    })),
  validateDimensions: () =>
    set((state: AppWindowState) => ({
      windows: Object.entries(state.windows).reduce((acc, [key, window]) => {
        acc[key] = {
          ...window,
          ...updateWindowDimensions(
            window,
            window.isMaximized
              ? { x: 0, y: 30, width: getWindowWidth(), height: getWindowHeight(true) }
              : window.dimensions,
            window.isMaximized
          )
        };
        return acc;
      }, {} as Record<string, AppWindow>)
    })),
  /* Brings an existing window to the front of the view */
  focusWindow: (id: string, isFocused = false) =>
    set((state: AppWindowState) => ({
      activeWindow: state.activeWindow === id && !isFocused ? null : id
    }))
});

export const selectWindows = (state: AppWindowState) => Object.values(state.windows);

export const selectActiveWindowId = (state: AppWindowState) => state.activeWindow;

const breakpointMap: { name: Breakpoint; maxWidth?: number }[] = [
  { name: 'xs', maxWidth: 639 },
  { name: 'sm', maxWidth: 767 },
  { name: 'md', maxWidth: 1023 },
  { name: 'lg', maxWidth: 1279 },
  { name: 'xl' }
];

function calculateBreakPoint(dimensions: AppWindowDimensions): Breakpoint {
  for (const breakpoint of breakpointMap) {
    if (!breakpoint.maxWidth || (dimensions.width || 0) <= breakpoint.maxWidth) {
      return breakpoint.name;
    }
  }

  return 'xl';
}

import { useEffect, useState } from 'react';

/** Configuration options for Use Hover hook */
export interface UseHoverProps {
  ref: React.RefObject<HTMLElement>;
  onHover?: () => void;
  onHoverDone?: () => void;
}

/** Handle mouseover events */
export function useHover({ ref, onHover, onHoverDone }: UseHoverProps) {
  const [value, setValue] = useState(false);

  function handleEvent(isHovered: boolean, callback?: () => void) {
    setValue(isHovered);
    callback?.();
  }

  useEffect(() => {
    const handleMouseOver = () => {
      handleEvent(true, onHover);
    };
    const handleMouseOut = () => {
      handleEvent(false, onHoverDone);
    };

    const nodeRef = ref?.current;

    if (nodeRef) {
      nodeRef.addEventListener('mouseover', handleMouseOver, { passive: true });
      nodeRef.addEventListener('mouseout', handleMouseOut, { passive: true });

      return () => {
        nodeRef.removeEventListener('mouseover', handleMouseOver);
        nodeRef.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref, onHover, onHoverDone]);

  return [value];
}

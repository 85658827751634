import { Size } from './state';

/** Application display data */
export interface App {
  name: AppName;
  title: string;
  icon: string;
  color: string;
  defaultSize: Size;
  isPinned: boolean;
}

export enum AppName {
  Welcome = 'Welcome',
  AboutMe = 'AboutMe',
  ContactMe = 'ContactMe',
  Skills = 'Skills',
  Resume = 'Resume',
  Accomplishments = 'Accomplishments',
  Draw = 'Draw',
  Projects = 'Projects',
  Calculator = 'Calculator',
  Theme = 'Theme',
  Photos = 'Photos',
  Sandbox = 'Sandbox'
}

export type Apps = Record<AppName, App>;

export const MAX_STACK_ORDER = 39;

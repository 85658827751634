import clsx from 'clsx';

export interface ColorGridProps {
  value?: string;

  colors: string[];

  onSelectColor: (color: string) => void;
}

const ColorGrid = ({ value, colors, onSelectColor }: ColorGridProps) => {
  return (
    <div className='grid grid-cols-8 gap-[2px] w-fit'>
      {colors.map((color) => (
        <div
          key={color}
          className={clsx(`w-12 h-12 cursor-pointer`, {
            'border border-2 border-window-contrast': value === color
          })}
          style={{ backgroundColor: `rgb(${color})` }}
          onClick={() => onSelectColor(color)}
        ></div>
      ))}
    </div>
  );
};

export default ColorGrid;

import { AllProps } from '../../models/global';

export interface AppWindowContentProps extends AllProps {
  children?: React.ReactNode;
}

const AppWindowContent = ({ children, ...props }: AppWindowContentProps) => {
  return <div {...props}>{children}</div>;
};

export default AppWindowContent;

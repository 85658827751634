import { useState } from 'react';
import { Variants, motion } from 'framer-motion';
import clsx from 'clsx';

import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';
import tiltproof1 from '../../../assets/projects/tiltproof1.jpg';
import portfolio1 from '../../../assets/projects/portfolio1.jpg';
import engine1 from '../../../assets/projects/engine1.jpg';
import FontIcon from '../../ui/FontIcon';
import './Projects.css';
import { bkpt } from '../../../util/breakpoint';

const sectionVariant: Variants = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      when: 'beforeChildren', //use this instead of delay
      staggerChildren: 0.1
    }
  }
};

const titleVariant: Variants = {
  initial: {
    x: 200,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1
  }
};

const tagVariant: Variants = {
  initial: {
    y: 150,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1
  }
}

const projects = [
  {
    id: 1,
    title: 'Tiltproof - LoL Statistics',
    description:
      'A league of legends statistic viewer. See stats broken down by champion, game mode, date and season. View history and much more!',
    bg: tiltproof1,
    tags: [
      { icon: 'typescript', name: 'Typescript' },
      { icon: 'vue-dot-js', name: 'VueJS' },
      { icon: 'go', name: 'Go' },
      { icon: 'node-dot-js', name: 'NodeJS' }
    ]
  },
  {
    id: 2,
    title: 'Portfolio - Operating System',
    description:
      'My portfolio in the style of an operating system. I wanted to use a lot of different technologies and libraries and have it be a project I can continously improve upon.',
    bg: portfolio1,
    tags: [
      { icon: 'typescript', name: 'Typescript' },
      { icon: 'react', name: 'React' },
      { icon: 'node-dot-js', name: 'NodeJS' },
      { icon: 'tailwindcss', name: 'Tailwind' }
    ]
  },
  {
    id: 3,
    title: 'Game Engine - 3D Web Game',
    description:
      'A game engine written from scratch in C/C++. The game runs in browsers and desktop. The engine uses OpenGL and supported Mesh loading, Animation, Lighting and terrain generation.',
    bg: engine1,
    tags: [
      { icon: 'cplusplus', name: 'C/C++' },
      { icon: 'javascript', name: 'Javascript' },
      { icon: 'openGL', name: 'OpenGL' },
      { icon: 'mongoDBb', name: 'MongoDB' },
      { icon: 'threejs', name: 'ThreeJS' }
    ]
  }
];

function Project({ project, isActive, setActive, breakpoint }: any) {
  const projectTitleClass = bkpt(breakpoint, 'project-title', '-translate-y-1/2 top-1/2 left-0 ml-2', { lg: '-mt-2' });

  return (
    <div
      className={clsx('item', { 'item-active': isActive })}
      onClick={setActive}
      style={{ backgroundImage: `url(${project.bg})` }}
    >
      <div className={projectTitleClass}>{project.title}</div>
      {isActive && (
        <motion.section
          initial='initial'
          animate='animate'
          variants={sectionVariant}
          className='absolute left-0 bottom-0 flex flex-col gap-4 p-10'
        >
          <motion.h2 variants={titleVariant} className='text-4xl font-semibold m-0 text-accent'>
            {project.title}
          </motion.h2>

          <motion.p variants={titleVariant} className='text-base max-w-[450px]'>
            {project.description}
          </motion.p>

          <motion.div
            initial='initial'
            animate='animate'
            variants={sectionVariant}
            className='flex gap-3 text-base text-base-contrast-400/80'
          >
            {project.tags.map((tag: { icon: string; name: string }, i: number) => (
              <motion.div className='bg-base-400 p-2 flex items-center gap-2 border-t-2 border-accent' key={i} variants={tagVariant}>
                <FontIcon icon={tag.icon}></FontIcon>
                <div>{tag.name}</div>
              </motion.div>
            ))}
          </motion.div>
        </motion.section>
      )}
    </div>
  );
}

export default function AppProjects({ appWindow }: AppWindowProps) {
  const [active, setActive] = useState(1);

  const contentClass = bkpt(appWindow.breakpoint, 'flex w-full h-full', 'flex-col', { lg: 'flex-row' });

  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow}></AppWindowHeader>

      <AppWindowContent className={contentClass}>
        {projects.map((project) => (
          <Project
            project={project}
            key={project.id}
            isActive={active === project.id}
            breakpoint={appWindow.breakpoint}
            setActive={() => setActive(project.id)}
          />
        ))}
      </AppWindowContent>
    </AppWindowContainer>
  );
}

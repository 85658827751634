import { motion } from 'framer-motion';

import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';
import { skillsData } from './skills-data';
import { animateStaggerIn } from '../../../animations/stagger-in';
import Hexagon from '../../ui/Hexagon';
import ImageIcon from '../../ui/ImageIcon';
import crown from '../../../assets/images/crown.png';

function AnimIconButton({ icon, title, color, children }: { icon: string; title: string; color: string, children: React.ReactNode }) {
  return (
    <motion.div variants={animateStaggerIn} className=''>
      <Hexagon color={color} icon={icon}>
        {title}
        {children}
      </Hexagon>
    </motion.div>
  );
}

export default function AppSkills({ appWindow }: AppWindowProps) {
  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow}></AppWindowHeader>

      <AppWindowContent className='p-6 overflow-auto text-base-contrast-500'>
        <motion.div variants={animateStaggerIn} initial='initial' animate='animate' className='flex flex-col gap-8'>
          {skillsData.map((group) => (
            <div className='flex flex-col gap-3' key={group.name}>
              <div className='flex items-center gap-3'>
                <h3 className='font-semibold'>{group.name}</h3>
                <h4 className='text-base-contrast-500/70'>{group.years}</h4>
              </div>
              <div className='flex gap-5 flex-wrap'>
                {group.skills.map((skill) => (
                  <AnimIconButton icon={skill.icon} title={skill.title} color={skill.color} key={skill.title}>
                    {skill.crown && (
                      <div className='absolute left-0 top-[-64px]'>
                        <ImageIcon icon={crown} width='40px' height='40px' alt='Favorite Technology' />
                      </div>
                    )}
                  </AnimIconButton>
                ))}
              </div>
            </div>
          ))}
        </motion.div>
      </AppWindowContent>
    </AppWindowContainer>
  );
}

import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';


export default function AppSandbox({ appWindow }: AppWindowProps) {
  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow}></AppWindowHeader>

      <AppWindowContent className=''>
      </AppWindowContent>
    </AppWindowContainer>
  );
}

import { useEffect, useRef, useState } from 'react';
import { AppWindowContainer, AppWindowContent, AppWindowHeader, AppWindowProps } from '../../window';

export default function AppDraw({ appWindow }: AppWindowProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  // const [lineWidth, setLineWidth] = useState(5);
  // const [lineColor, setLineColor] = useState('black');
  // const [lineOpacity, setLineOpacity] = useState(0.1);

  const updateCanvasSize = () => {
    if (canvasRef.current) {
      canvasRef.current.width = 800;
      canvasRef.current.height = 600;
    }
  };

  useEffect(() => {
    ctxRef.current = canvasRef.current?.getContext('2d') || null;
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    if (ctxRef.current) {
      ctxRef.current.lineCap = 'round';
      ctxRef.current.lineJoin = 'round';
      // ctxRef.current.globalAlpha = lineOpacity;
      // ctxRef.current.strokeStyle = lineColor;
      // ctxRef.current.lineWidth = lineWidth;
    }
  }, []);

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (ctxRef.current) {
      ctxRef.current.beginPath();
      ctxRef.current.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
      setIsDrawing(true);
    }
  };

  const endDrawing = () => {
    if (ctxRef.current) {
      ctxRef.current.closePath();
      setIsDrawing(false);
    }
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!isDrawing || !ctxRef.current) {
      return;
    }

    ctxRef.current.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    ctxRef.current.stroke();
  };

  return (
    <AppWindowContainer appWindow={appWindow}>
      <AppWindowHeader appWindow={appWindow} />

      <AppWindowContent>
        <canvas onMouseDown={startDrawing} onMouseUp={endDrawing} onMouseMove={draw} ref={canvasRef} />
      </AppWindowContent>
    </AppWindowContainer>
  );
}

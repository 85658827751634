import { useState, useEffect } from 'react';
import { Variants, motion } from 'framer-motion';

const loadingText = 'Crosman';
const loadingLetters = loadingText.split('');

const containerVariant: Variants = {
  initial: {},
  phase1: {
    transition: {
      when: 'beforeChildren', //use this instead of delay,
      staggerChildren: 0.1
    }
  }
};

const letterVariant: Variants = {
  initial: {
    opacity: 0,
    y: -500
  },
  phase1: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'anticipate'
    }
  },
  phase2: {
    x: -250,
    y: 0,
    opacity: 0,
    transition: {
      ease: 'anticipate'
    }
  }
};

const logoVariant: Variants = {
  initial: {
    y: -300
  },
  phase1: {
    y: 0,
    transition: {
      delay: 0.75,
      duration: 0.6,
      ease: 'anticipate'
    }
  },
  phase2: {
    x: -200,
    y: 0,
    rotate: 720,
    transition: {
      ease: 'anticipate'
    }
  },
  phase3: {
    x: -200,
    y: 0,
    rotate: 720,
    transition: {
      ease: 'anticipate'
    }
  }
};


const bgVariant: Variants = {
  initial: {
    opacity: 1
  },
  phase3: {
    opacity: 0,
    transition: {
      duration: 1.0,
      ease: 'easeIn'
    }
  }
};

export interface LoaderProps {
  done: () => void;
}

export const Loader = ({ done }: LoaderProps) => {
  const [phase, setPhase] = useState('phase1');

  useEffect(() => {
    const timeout = setTimeout(() => setPhase('phase2'), 1600);
    const timeout2 = setTimeout(() => setPhase('phase3'), 2100);
    const timeout3 = setTimeout(done, 3500);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  }, []);

  return (
    <motion.div className='absolute inset-0 z-50 bg-[#070416]' initial='initial' animate={phase} variants={bgVariant}>
      <div className='abs-center flex text-8xl overflow-hidden'>
        <motion.div className='flex' initial='initial' animate={phase} variants={containerVariant}>
          {loadingLetters.map((letter) => (
            <motion.div key={letter} variants={letterVariant}>
              {letter}
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          initial='initial'
          animate={phase}
          variants={logoVariant}
          className='hexagon flex items-center justify-center bg-accent w-[125px] h-[125px] text-7xl pb-[6px] font-semibold'
        >
          OS
        </motion.div>
      </div>
    </motion.div>
  );
};

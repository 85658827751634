/**
 * Calculate a new width/height while perserving the aspect ratio
 * @param originalWidth width to convert
 * @param originalHeight height to convert
 * @param targetWidth desired width
 * @param targetHeight desired height
 * @returns
 */
export function calculateAspectRatioDimensions(
  originalWidth: number,
  originalHeight: number,
  targetWidth: number,
  targetHeight: number
): { width: number; height: number } {
  let newWidth = 0,
    newHeight = 0;

  if (targetWidth) {
    newWidth = targetWidth;
    newHeight = newWidth / (originalWidth / originalHeight);
  } else if (targetHeight) {
    newHeight = targetHeight;
    newWidth = newHeight * (originalWidth / originalHeight);
  }

  return { width: newWidth, height: newHeight };
}

import { AppName, Apps } from '../models/app';

/** List of applications that will be displayed on the desktop */
export const installedApps: Apps = {
  [AppName.Welcome]: {
    name: AppName.Welcome,
    title: 'Welcome',
    icon: 'folder-open',
    color: '',
    isPinned: false,
    defaultSize: {
      width: 500,
      height: 500
    }
  },
  [AppName.AboutMe]: {
    name: AppName.AboutMe,
    title: 'About Me',
    color: 'bg-gradient-blue',
    icon: 'info',
    isPinned: true,
    defaultSize: {
      width: 800,
      height: 900
    }
  },
  [AppName.Skills]: {
    name: AppName.Skills,
    title: 'Skills',
    color: 'bg-gradient-red',
    icon: 'quill',
    isPinned: true,
    defaultSize: {
      width: 650,
      height: 600
    }
  },
  [AppName.ContactMe]: {
    name: AppName.ContactMe,
    title: 'Contact Me',
    color: 'bg-gradient-green',
    icon: 'address-card',
    isPinned: true,
    defaultSize: {
      width: 600,
      height: 350
    }
  },
  [AppName.Resume]: {
    name: AppName.Resume,
    title: 'Resume',
    color: 'bg-gradient-purple',
    icon: 'file-text',
    isPinned: true,
    defaultSize: {
      width: 875,
      height: 850
    }
  },
  [AppName.Projects]: {
    name: AppName.Projects,
    title: 'Projects',
    color: 'bg-gradient-orange',
    icon: 'lightbulb-o',
    isPinned: true,
    defaultSize: {
      width: 800,
      height: 700
    }
  },
  [AppName.Accomplishments]: {
    name: AppName.Accomplishments,
    title: 'Accomplishments',
    color: 'bg-gradient-dark-blue',
    icon: 'calendar',
    isPinned: true,
    defaultSize: {
      width: 1000,
      height: 875
    }
  },
  [AppName.Draw]: {
    name: AppName.Draw,
    title: 'Draw',
    color: 'bg-gradient-grey',
    icon: 'paint-brush',
    isPinned: false,
    defaultSize: {
      width: 800,
      height: 600
    }
  },
  [AppName.Calculator]: {
    name: AppName.Calculator,
    title: 'Calculator',
    color: 'bg-gradient-dark-blue',
    icon: 'calculator',
    isPinned: false,
    defaultSize: {
      width: 350,
      height: 500
    }
  },
  [AppName.Theme]: {
    name: AppName.Theme,
    title: 'Theme',
    icon: 'paint-brush',
    color: 'bg-gradient-pink',
    isPinned: true,
    defaultSize: {
      width: 900,
      height: 780
    }
  },
  [AppName.Photos]: {
    name: AppName.Photos,
    color: 'bg-gradient-black',
    title: 'Photos',
    icon: 'image',
    isPinned: true,
    defaultSize: {
      width: 900,
      height: 780
    }
  },
  [AppName.Sandbox]: {
    name: AppName.Sandbox,
    title: 'Sandbox',
    color: 'bg-gradient-grey',
    icon: 'cog',
    isPinned: false,
    defaultSize: {
      width: 1000,
      height: 875
    }
  }
};

export const installedAppList = Object.values(installedApps);
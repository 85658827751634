import clsx from "clsx";

export interface FontIconProps {
  /** Icon to display */
  icon: string;

  /** Optional classname to apply to div */
  className?: string;
}

/**
 * Renders an icon defined in the app font file
 */
const FontIcon = ({ icon, className = ''}: FontIconProps) => {
  const fullClassName = clsx(`icon icon-${icon}`, className);

  return <div className={fullClassName}></div>;
};

export default FontIcon;

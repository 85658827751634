export interface TimelineProps extends React.PropsWithChildren {}

const Timeline = ({ children }: TimelineProps) => {
  return (
    <div className='relative after:content-[""] after:absolute after:top-0 after:left-1/2 after:bottom-0 after:w-[4px] after:-ml-[2px] after:bg-accent max-w-[1200px] mx-auto'>
      {children}
    </div>
  );
};

export default Timeline;

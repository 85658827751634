export function getWindowWidth() {
  return window.innerWidth;
}

export function getWindowHeight(includeToolbar = false) {
  return includeToolbar ? window.innerHeight - 125 : window.innerHeight;
}

export function getWindowSize(includeToolbar = false) {
  return { width: getWindowWidth(), height: getWindowHeight(includeToolbar) };
}

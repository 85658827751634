import { getWindowWidth } from '../util/window';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const breakpointWeights = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
  '2xl': 6
};

const breakpointMap: { name: Breakpoint; maxWidth?: number }[] = [
  { name: 'xs', maxWidth: 639 },
  { name: 'sm', maxWidth: 767 },
  { name: 'md', maxWidth: 1023 },
  { name: 'lg', maxWidth: 1279 },
  { name: 'xl' }
];

export interface AppState {
  hasVisited: boolean;
  isLoading: boolean;
  breakpoint: Breakpoint;
  setIsLoading: (isLoading: boolean) => void;
  setHasVisisted: () => void;
  restartApp: () => void;
  calculateBreakpoint: () => void;
}

export const createAppSlice: (set: (fn: (state: AppState) => Partial<AppState>) => void) => AppState = (set) => ({
  hasVisited: false,
  isLoading: true,
  setHasVisisted: () => set(() => ({ hasVisited: true })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  breakpoint: calculateBreakPoint(),
  restartApp: () => set(() => ({ hasVisited: false, isLoading: true })),
  calculateBreakpoint: () => set(() => ({ breakpoint: calculateBreakPoint() }))
});

function calculateBreakPoint(): Breakpoint {
  const width = getWindowWidth();

  for (const breakpoint of breakpointMap) {
    if (!breakpoint.maxWidth || width <= breakpoint.maxWidth) {
      return breakpoint.name;
    }
  }

  return 'xl';
}

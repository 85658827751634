import { useState, useEffect } from 'react';
import { Timer } from '../../models/timer';

const HeaderDate = () => {
  // Keep track of date
  const [date, setDate] = useState(new Date());

  // Update date state
  useEffect(() => {
    let timer: Timer;

    function updateTime() {
      const now = new Date();
      const delayUntilNextMinute = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds() + 1);

      timer = setTimeout(() => {
        setDate(new Date());
        updateTime();
      }, delayUntilNextMinute);
    }

    updateTime();

    return () => clearTimeout(timer);
  });

  return (
    <div className='text-sm'>
      <div>
        {date.toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })}
      </div>
    </div>
  );
};

export default HeaderDate;

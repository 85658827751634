import clsx from 'clsx';
import { AllProps } from '../../models/global';
import FontIcon from './FontIcon';

import './Button.css';

export interface ButtonProps extends AllProps {
  /** Content to render inside of button */
  children: React.ReactNode;
  /** Optional icon to display to the left of the button content */
  icon?: string;
  /** Optional styling for "hex" like button shape */
  isHex?: boolean;
  /** Optional class to apply to button */
  className?: string;
  /** Optional class to apply dsiabled state to button */
  disabled?: boolean;
}

/**
 * Renders a common app button
 */
const Button = ({ children, icon, isHex, className, disabled, ...props }: ButtonProps) => {
  const btnClass = clsx(
    'flex gap-2 items-center justify-center px-1 py-2 bg-accent text-base text-accent-contrast shadow-lg text-white',
    { 'opacity-50': disabled },
    { 'hover:bg-accent-light active:bg-accent-dark': !disabled },
    { 'button-hex': isHex },
    className
  );
  return (
    <button disabled={disabled} className={btnClass} {...props}>
      {icon && <FontIcon icon={icon} />}
      <span>{children}</span>
    </button>
  );
};

export default Button;

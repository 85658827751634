export interface TextInputProps {
  value: string;

  onChange: (value: string) => void;

  type?: string;

  placeholder?: string;
}

const TextInput = ({ value, onChange, type = 'text', placeholder = '' }: TextInputProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={handleInputChange}
      className='block w-full py-1 px-3 border border-base-300 bg-base-400 shadow-sm rounded-sm focus:outline-none focus:ring-accent focus:border-accent text-base-contrast-400'
    />
  );
};

export default TextInput;

import { useState, useLayoutEffect } from 'react';
import { debounce } from '../util/debounce';

export function useWindowSize() {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    const resizeCallback = debounce(handleResize, 10);

    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return size;
}

import { AllProps } from '../../models/global';
import { motion } from 'framer-motion';

export interface HexagonIconProps extends AllProps {
  children: React.ReactNode;
}

/**
 * Render an icon from a url
 */
export default function HexagonIcon({ children, ...props }: HexagonIconProps) {
  return (
    <div {...props}>
      <motion.svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' className='w-full h-full'>
        <motion.path
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          stroke='rgb(var(--accent))'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={6}
          fill='none'
          d='M 50 5 L 95 30 L 95 70 L 50 95 L 5 70 L 5 30 Z'
        />
      </motion.svg>

      <div className='absolute abs-center'>{children}</div>
    </div>
  );
}

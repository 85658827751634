import React, { useRef, useState } from 'react';
import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import GridLayout from 'react-grid-layout';

import FileIcon from './AppFileIcon';
import { useGlobalStore } from '../../state/store';
import { useWindowSize } from '../../hooks/UseWindowSize';
import { selectFiles } from '../../state/fileSlice';
import { AppName } from '../../models/app';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import FontIcon from '../ui/FontIcon';
import { selectActiveFile } from '../../state/desktopSlice';
import { downloadFileFromUrl } from '../../util/downloadFileFromUrl';

const GRID_ROW_HEIGHT = 85;
const GRID_COL_WIDTH = 95;

export default function AppList() {
  const ref = useRef<HTMLDivElement>(null);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
  const [isFileMenuOpen, setIsFileMenuOpen] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState<{ x: number; y: number }>();
  const selectFile = useGlobalStore((state) => state.selectFile);
  const addWindow = useGlobalStore((state) => state.addWindow);
  const files = useGlobalStore(selectFiles);
  const selectedFileId = useGlobalStore(selectActiveFile);

  const selectedFile = files.find((f) => f.id === selectedFileId);

  const size = useWindowSize();

  const handleDesktopContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDesktopMenuOpen(true);
    setAnchorPoint({ x: e.clientX, y: e.clientY });
  };

  const handleFileContextMenu = (e: React.MouseEvent, fileId: string | undefined) => {
    e.preventDefault();

    selectFile(fileId);

    setIsFileMenuOpen(true);
    setAnchorPoint({ x: e.clientX, y: e.clientY });
  };

  const openFile = () => {
    if (selectedFile) {
      addWindow(selectedFile.appName, selectedFile.args);
    }
  };

  const downloadFile = () => {
    if (selectedFile?.args?.length) {
      downloadFileFromUrl(selectedFile.args[0], selectedFile.name)
    }
  };

  const resetFileSelection = () => selectFile();
  useOutsideClick({ ref, onClickOutside: resetFileSelection });

  function calculatePosition(totalHeight: number, index: number, itemHeight = 100) {
    const maxItemsPerColumn = Math.floor(totalHeight / itemHeight);

    const x = Math.floor(index / maxItemsPerColumn);
    const y = index % maxItemsPerColumn;

    return { x, y };
  }

  const layout = files.map((file, index) => {
    return { i: file.name, ...calculatePosition(size.height, index), w: 1, h: 1, isResizable: false };
  });

  return (
    <div onContextMenu={handleDesktopContextMenu} onClick={resetFileSelection} ref={ref}>
      <GridLayout
        cols={size.width / GRID_COL_WIDTH}
        rowHeight={GRID_ROW_HEIGHT}
        layout={layout}
        width={size.width}
        compactType={null}
        isBounded={true}
        useCSSTransforms={true}
        resizeHandle={null}
        containerPadding={[16, 16]}
        style={{
          height: `calc(100vh - ${75}px)`,
          marginTop: '35px'
        }}
      >
        {files.map((file) => (
          <div key={file.name}>
            <FileIcon
              file={file}
              addWindow={() => addWindow(file.appName, file.args || [])}
              selectFile={() => selectFile(file.id)}
              onContextMenu={(e: React.MouseEvent) => handleFileContextMenu(e, file.id)}
            />
          </div>
        ))}
      </GridLayout>

      {isDesktopMenuOpen && (
        <ControlledMenu
          state={isDesktopMenuOpen ? 'open' : 'closed'}
          anchorPoint={anchorPoint}
          onClose={() => setIsDesktopMenuOpen(false)}
          className='szh-desktop'
        >
          <MenuItem onClick={() => addWindow(AppName.Theme)}>
            <FontIcon icon='paint-brush' /> Personalize
          </MenuItem>
        </ControlledMenu>
      )}

      {isFileMenuOpen && (
        <ControlledMenu
          state={isFileMenuOpen ? 'open' : 'closed'}
          anchorPoint={anchorPoint}
          onClose={() => setIsFileMenuOpen(false)}
        >
          <MenuItem onClick={openFile}>
            <FontIcon icon='file-o' /> Open
          </MenuItem>
          {!!selectedFile?.args && (
            <MenuItem onClick={downloadFile}>
              <FontIcon icon='cloud-download' /> Download
            </MenuItem>
          )}
        </ControlledMenu>
      )}
    </div>
  );
}
